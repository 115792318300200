<div class="main" fxLayout="column">
  <mat-dialog-content>
    <h2 fxFlexAlign="center" mat-dialog-title>{{'DIALOGS.PROJECTSAVEWARNING.TITLE' | i18next }}</h2>
    <div fxLayout="column">
      <p class="content">{{'DIALOGS.PROJECTSAVEWARNING.TEXT' | i18next}}</p>
    </div>
  </mat-dialog-content>

  <div class="buttondiv" fxLayout="row">
    <button class="yesButton" fxFlexAlign="flex-start" (click)="Change()">{{'DIALOGS.PROJECTSAVEWARNING.SAVE' | i18next }}</button>
    <button class="noButton" fxFlexAlign="flex-end" (click)="Cancel()">{{'DIALOGS.PROJECTSAVEWARNING.TEMP' | i18next }}</button>
  </div>
</div>
