import { Injectable, EventEmitter } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {lastValueFrom, Observable, throwError} from 'rxjs';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {UserManagementService} from './user-management.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {or} from 'ajv/dist/compile/codegen';

@Injectable({
  providedIn: 'root'
})
export class BluectrlApiService {

  public DefaultPostHeader: HttpHeaders;
  public DefaultGetHeader: HttpHeaders;


  constructor(private http: HttpClient,
              private usermanagement: UserManagementService,
              private jwtHelper: JwtHelperService) {
    registerLocaleData(localeDe);

    this.usermanagement.ReconnectRequested.subscribe(this.RequestReconnect.bind(this));

    this.DefaultPostHeader = new HttpHeaders();
    const utcOffset = -(new Date().getTimezoneOffset());
    this.DefaultPostHeader.set('Content-Type', 'application/x-www-form-urlencoded');
    this.DefaultPostHeader.set('accept', 'application/json');

    this.DefaultGetHeader = new HttpHeaders();
    this.DefaultGetHeader.append('utc-offset', utcOffset.toString());
    this.DefaultGetHeader.append('platform', 'WEB');
    this.DefaultGetHeader.append('app-version', '0.1.0');
    this.DefaultGetHeader.append('version', '1.0');
    this.DefaultGetHeader.append('accept', 'application/json');
    this.DefaultGetHeader.append('Access-Control-Allow-Origin', '*');
    this.DefaultGetHeader.append('Access-Control-Allow-Origin', 'localhost:4200');
    this.DefaultGetHeader.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    this.DefaultGetHeader.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // if (error.status === 406) {
      //  this.notAcceptableError.emit(error);
      // }
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        ` Backend returned code ${error.status},` +
        `body was: ${error.message}`);


      //#!!TODO put this in again - need to remove this temporary for the password work around!!    
      // if (error.status === 401) {
      //   // LOGOUT
      //   this.usermanagement.LogOut();
      // }

    }
    // Return an observable with a user-facing error message.
    return throwError(
      error);
  }

  private RequestReconnect(userdata: any) {
    if (userdata) {
      if (userdata.user && userdata.password) {
        this.Login(userdata.user, userdata.password).subscribe((data: any) => {
          if (data.token_type === 'bearer') {
            this.usermanagement.SetToken(data.access_token);
            // SET CURRENT USER
          }
        }, (() => {
          this.usermanagement.LogOut();
          return;

        }));
      } else {
        this.usermanagement.LogOut();
      }
    } else {
      this.usermanagement.LogOut();
    }

  }

  //Tmp handled as promise for simplicity
  public async updateUser(password: string, email: string) {
    const configUrl = environment.bluectrlapi + '/users/'+email;

    return lastValueFrom(this.http.put(configUrl, {
      password: password
    }));

  }

  public Login(user: string, pw: string) {
    if (user && pw) {

      const data2 = new HttpParams()
        .set('username', user)
        .set('password', pw);

      const configUrl = environment.bluectrlapi + '/auth/jwt/login';

      return this.http.post(configUrl, data2, )
        .pipe(
          catchError(this.handleError)
        );
    }

  }

  public getUserInfo() {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/users/me/detail';
        const header = new HttpHeaders();
        header.append('accept', 'application/json');

        return this.http.get(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public GetUserModules(ward: string): Observable<any> {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {
        let configUrl = environment.bluectrlapi + '/modules';

        if (ward) {
          configUrl += '/?ward_id=' + ward + '&skip=0&limit=200&show_deleted=false';
        } else {
          configUrl += '/?skip=0&limit=200&show_deleted=false';
        }

        const header = new HttpHeaders();
      header.append('accept', 'application/json');




      return this.http.get(configUrl, { headers: header })
        .pipe(
          catchError(this.handleError)
        );
      } else {
        this.usermanagement.LogOut();
      }
    } else {
      this.usermanagement.LogOut();
    }
  }

  public GetAllModules() {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {
        const configUrl = environment.bluectrlapi + '/modules/?show_all=true&skip=0&limit=200&show_deleted=false';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');


        return this.http.get(configUrl, { headers: header })
          .pipe(
            catchError(this.handleError)
          );
      } else {
        this.usermanagement.LogOut();
      }
    } else {
      this.usermanagement.LogOut();
    }
  }

  public getUsers() {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/users/';
        const header = new HttpHeaders();
        header.append('accept', 'application/json');

        return this.http.get(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public addNewUser(email: string, firstname: string, lastname: string, organization: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/users/';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');

        const data =  {
          email: email,
          first_name: firstname,
          last_name: lastname,
          organization_id: organization
        };

        return this.http.post(configUrl, data, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public deleteUser(id: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/users/' + id;

        const header = new HttpHeaders();
        header.append('accept', 'application/json');



        return this.http.delete(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public getRecipes(skip = 0, take = 100, showdeleted = false) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {
        const configUrl = environment.bluectrlapi + '/recipes/?skip='+skip+'&limit='+take+'&show_deleted='+showdeleted;
        const header = new HttpHeaders();
        header.append('accept', 'application/json');

        return this.http.get(configUrl, {headers: header})
          .pipe(
            catchError(error => {
              this.handleError(error);
              return throwError(error);
            })
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public getRecipeDetail(recipeId: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {


        const configUrl = environment.bluectrlapi + '/recipes/' + recipeId + '/';
        const header = new HttpHeaders();
        header.append('accept', 'application/json');



        return this.http.get(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public getRecipesContent(recipe_id: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {


        const configUrl = environment.bluectrlapi + '/recipes/' + recipe_id + '/data';
        const header = new HttpHeaders();
        header.append('accept', 'application/json');



        return this.http.get(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public AddNewRecipe(recipe: any) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/recipes/';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');



        return this.http.post(configUrl, recipe, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public UpdateRecipe(recipe: any) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {


        const configUrl = environment.bluectrlapi + '/recipes/';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');


        return this.http.put(configUrl, recipe, {headers: header})
          .pipe(
            catchError(error => {  
              this.handleError(error);
          
              return throwError(error);
            })
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public DeleteRecipe(recipeId: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {


        const configUrl = environment.bluectrlapi + '/recipes/' + recipeId + '/';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');


        return this.http.delete(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public transferRecipe(recipeId: string, organizationId: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/recipes/' + recipeId + '/copy?organization_id=' + organizationId;

        const header = new HttpHeaders();
        header.append('accept', 'application/json');


        return this.http.put(configUrl, null, {headers: header})
          .pipe(
            catchError(error => {
              this.handleError(error);
              return throwError(error);
            })
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public GetDms() {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {
        const configUrl = environment.bluectrlapi + '/specifications/latest?environment=' + this.getCurrentSystem();
        
        const header = new HttpHeaders();
        header.append('accept', 'application/json');
        return this.http.get(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  private getCurrentSystem(): string {
    const api = environment.bluectrlapi;

    if (api.indexOf('dev.') >= 0) {
      return 'dev';
    } else if (api.indexOf('staging.') >= 0) {
      return 'stage';
    } else if (api.indexOf('test.') >= 0) {
      return 'test';
    } else {
      return 'prod';
    }

  }

  public getOrganizations() {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {
        const configUrl = environment.bluectrlapi + '/organizations/';
        const header = new HttpHeaders();
        header.append('accept', 'application/json');

        return this.http.get(configUrl, {headers: header})
          .pipe(
            catchError(error => {
              this.handleError(error);
              return throwError(error);
            })
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public addNewOrganization(name: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/organizations/';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');

        const data =  {
          name: name
        };


        return this.http.post(configUrl, data, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public deleteOrganization(id: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/organizations/' + id + '/';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');



        return this.http.delete(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public updateOwner(newOwner: string, moduleSerial: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {


        const configUrl = environment.bluectrlapi + '/modules/' + moduleSerial + '/owner/?new_owner_id=' + newOwner;

        const header = new HttpHeaders();
        header.append('accept', 'application/json');


        return this.http.put(configUrl, null, {headers: header})
          .pipe(
            catchError(error => {
              this.handleError(error);
              return throwError(error);
            })
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public updateHolder(newholder: string, moduleSerial: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {


        const configUrl = environment.bluectrlapi + '/modules/' + moduleSerial + '/holder/?new_holder_id=' + newholder;

        const header = new HttpHeaders();
        header.append('accept', 'application/json');


        return this.http.put(configUrl, null, {headers: header})
          .pipe(
            catchError(error => {
              this.handleError(error);
              return throwError(error);
            })
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public addNewModule(serial: string, organization: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/modules/';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');

        const data = {
          serial: serial,
          owner_id: organization
        };


        return this.http.post(configUrl, data, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

  public deleteCustomerModule(serial: string) {
    if (this.usermanagement.currentToken) {
      if (!this.jwtHelper.isTokenExpired(this.usermanagement.currentToken)) {

        const configUrl = environment.bluectrlapi + '/modules/' + serial + '/';

        const header = new HttpHeaders();
        header.append('accept', 'application/json');



        return this.http.delete(configUrl, {headers: header})
          .pipe(
            catchError(this.handleError)
          );

      } else {
        this.usermanagement.LogOut();
      }

    } else {
      this.usermanagement.LogOut();
    }
  }

}
