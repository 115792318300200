import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlinkingService {

  public BlinkOn = new EventEmitter();
  public BlinkOff = new EventEmitter();
  private handler: any;
  private state = false;

  constructor() {
    this.startBlinking();

  }

  private startBlinking() {
    this.handler = setInterval(() => {
      if (this.state) {
        this.BlinkOff.emit();
      } else {
        this.BlinkOn.emit();
      }
      this.state = !this.state;
    }, 500);
  }
}
