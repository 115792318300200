
export const ScreenLinings: any[] = [
  {
    module: 'B0301-001',
    component: 'SIB01',
    parameter: 'Panel',
    linings: [
      {
        id: 1,
        mw: 0.1,
        name: 'Drahtmasche MW 0,100'
      },
      {
        id: 2,
        mw: 0.125,
        name: 'Drahtmasche MW 0,125'
      },
      {
        id: 3,
        mw: .21,
        name: 'Drahtmasche MW 0,210'
      },
      {
        id: 4,
        mw: .25,
        name: 'Drahtmasche MW 0,250'
      },
      {
        id: 5,
        mw: .32,
        name: 'Drahtmasche MW 0,32'
      },
      {
        id: 6,
        mw: .5,
        name: 'Drahtmasche MW 0,500'
      },
      {
        id: 7,
        mw: .63,
        name: 'Drahtmasche MW 0,630'
      },
      {
        id: 8,
        mw: .8,
        name: 'Drahtmasche MW 0,800'
      },
      {
        id: 9,
        mw: 1.0,
        name: 'Drahtmasche MW 1,00'
      },
      {
        id: 10,
        mw: 1.250,
        name: 'Drahtmasche MW 1,25'
      },
      {
        id: 11,
        mw: 1.5,
        name: 'Drahtmasche MW 1,50'
      },
      {
        id: 12,
        mw: 1.6,
        name: 'Drahtmasche MW 1,60'
      },
      {
        id: 13,
        mw: 1.8,
        name: 'Drahtmasche MW 1,800'
      },
      {
        id: 14,
        mw: 2.0,
        name: 'Drahtmasche MW 2,00'
      },
      {
        id: 15,
        mw: 2.5,
        name: 'Drahtmasche MW 2,50'
      },
      {
        id: 16,
        mw: 3.15,
        name: 'Drahtmasche MW 3,15'
      },
      {
        id: 17,
        mw: 3.5,
        name: 'Drahtmasche MW 3,50'
      },
      {
        id: 18,
        mw: 4.0,
        name: 'Drahtmasche MW 4,00'
      },
      {
        id: 19,
        mw: 5.0,
        name: 'Drahtmasche MW 5,00'
      },
      {
        id: 20,
        mw: 6.3,
        name: 'Drahtmasche MW 6,30'
      },
      {
        id: 21,
        mw: 8.0,
        name: 'Drahtmasche MW 8,00'
      },
      {
        id: 22,
        mw: 10.0,
        name: 'Drahtmasche MW 10,00'
      },
      {
        id: 23,
        mw: 12.5,
        name: 'Drahtmasche MW 12,50'
      },
      {
        id: 24,
        mw: 16.0,
        name: 'Drahtmasche MW 16,00'
      },
      {
        id: 25,
        mw: 22.0,
        name: 'Drahtmasche MW 22,00'
      },
      {
        id: 26,
        mw: 30.0,
        name: 'Drahtmasche MW 30,00'
      },
      {
        id: 27,
        mw: 32.0,
        name: 'Drahtmasche MW 32,00'
      },
      {
        id: 28,
        mw: 40.0,
        name: 'Drahtmasche MW 40,00'
      },
      {
        id: 29,
        mw: 2.4,
        name: 'Drahtmasche MW 0,8x2,4'
      },
      {
        id: 30,
        mw: 3.1,
        name: 'Drahtmasche MW 1,0x3,0'
      },
      {
        id: 31,
        mw: .0,
        name: 'Benutzerdefiniertes Sieb'
      },
    ]
  },
  {
    module: 'B0301-001',
    component: 'SIB01',
    parameter: 'Panel2',
    linings: [
      {
        id: 1,
        mw: 0.1,
        name: 'Drahtmasche MW 0,100'
      },
      {
        id: 2,
        mw: 0.125,
        name: 'Drahtmasche MW 0,125'
      },
      {
        id: 3,
        mw: .21,
        name: 'Drahtmasche MW 0,210'
      },
      {
        id: 4,
        mw: .25,
        name: 'Drahtmasche MW 0,250'
      },
      {
        id: 5,
        mw: .32,
        name: 'Drahtmasche MW 0,32'
      },
      {
        id: 6,
        mw: .5,
        name: 'Drahtmasche MW 0,500'
      },
      {
        id: 7,
        mw: .63,
        name: 'Drahtmasche MW 0,630'
      },
      {
        id: 8,
        mw: .8,
        name: 'Drahtmasche MW 0,800'
      },
      {
        id: 9,
        mw: 1.0,
        name: 'Drahtmasche MW 1,00'
      },
      {
        id: 10,
        mw: 1.250,
        name: 'Drahtmasche MW 1,25'
      },
      {
        id: 11,
        mw: 1.5,
        name: 'Drahtmasche MW 1,50'
      },
      {
        id: 12,
        mw: 1.6,
        name: 'Drahtmasche MW 1,60'
      },
      {
        id: 13,
        mw: 1.8,
        name: 'Drahtmasche MW 1,800'
      },
      {
        id: 14,
        mw: 2.0,
        name: 'Drahtmasche MW 2,00'
      },
      {
        id: 15,
        mw: 2.5,
        name: 'Drahtmasche MW 2,50'
      },
      {
        id: 16,
        mw: 3.15,
        name: 'Drahtmasche MW 3,15'
      },
      {
        id: 17,
        mw: 3.5,
        name: 'Drahtmasche MW 3,50'
      },
      {
        id: 18,
        mw: 4.0,
        name: 'Drahtmasche MW 4,00'
      },
      {
        id: 19,
        mw: 5.0,
        name: 'Drahtmasche MW 5,00'
      },
      {
        id: 20,
        mw: 6.3,
        name: 'Drahtmasche MW 6,30'
      },
      {
        id: 21,
        mw: 8.0,
        name: 'Drahtmasche MW 8,00'
      },
      {
        id: 22,
        mw: 10.0,
        name: 'Drahtmasche MW 10,00'
      },
      {
        id: 23,
        mw: 12.5,
        name: 'Drahtmasche MW 12,50'
      },
      {
        id: 24,
        mw: 16.0,
        name: 'Drahtmasche MW 16,00'
      },
      {
        id: 25,
        mw: 22.0,
        name: 'Drahtmasche MW 22,00'
      },
      {
        id: 26,
        mw: 30.0,
        name: 'Drahtmasche MW 30,00'
      },
      {
        id: 27,
        mw: 32.0,
        name: 'Drahtmasche MW 32,00'
      },
      {
        id: 28,
        mw: 40.0,
        name: 'Drahtmasche MW 40,00'
      },
      {
        id: 29,
        mw: 2.4,
        name: 'Drahtmasche MW 0,8x2,4'
      },
      {
        id: 30,
        mw: 3.1,
        name: 'Drahtmasche MW 1,0x3,0'
      },
      {
        id: 31,
        mw: .0,
        name: 'Benutzerdefiniertes Sieb'
      },
    ]
  },
  {
    module: 'B0302-001',
    component: 'SIB01',
    parameter: 'UpperPanel',
    linings: [
      {
        id: 1,
        mw: 12.0,
        name: '3D-Standard MW 12'
      },
      {
        id: 2,
        mw: 20.0,
        name: '3D-Standard MW 20'
      },
      {
        id: 3,
        mw: 33.0,
        name: '3D-Standard MW 33'
      },
      {
        id: 4,
        mw: 45.0,
        name: '3D-Standard MW 45'
      },
      {
        id: 5,
        mw: 60.0,
        name: '3D-Standard MW 60'
      },
      {
        id: 6,
        mw: 80.0,
        name: '3D-Standard MW 80'
      },
      {
        id: 7,
        mw: 100.0,
        name: '3D-Standard MW 100'
      },
      {
        id: 8,
        mw: 125.0,
        name: '3D-Standard MW 125'
      },
      {
        id: 9,
        mw: 140.0,
        name: '3D-Standard MW 140'
      },
      {
        id: 10,
        mw: 0.0,
        name: 'Benutzerdefiniertes Sieb'
      },

    ]
  },
  {
    module: 'B0302-001',
    component: 'SIB01',
    parameter: 'LowerPanel',
    linings: [
      {
        id: 1,
        mw: 2.0,
        name: 'PU-Matte MW 2,0'
      },
      {
        id: 2,
        mw: 2.5,
        name: 'PU-Matte MW 2,5'
      },
      {
        id: 3,
        mw: 3.0,
        name: 'PU-Matte MW 3,0'
      },
      {
        id: 4,
        mw: 4.0,
        name: 'PU-Matte MW 4,0'
      },
      {
        id: 5,
        mw: 5.0,
        name: 'PU-Matte MW 5,0'
      },
      {
        id: 6,
        mw: 6.0,
        name: 'PU-Matte MW 6,0'
      },
      {
        id: 7,
        mw: 8.0,
        name: 'PU-Matte MW 8,0'
      },
      {
        id: 8,
        mw: 10.0,
        name: 'PU-Matte MW 10,0'
      },
      {
        id: 9,
        mw: 12.0,
        name: 'PU-Matte MW 12,0'
      },
      {
        id: 10,
        mw: 15.0,
        name: 'PU-Matte MW 15,0'
      },
      {
        id: 11,
        mw: 18.0,
        name: 'PU-Matte MW 18,0'
      },
      {
        id: 12,
        mw: 0.0,
        name: 'Benutzerdefiniertes Sieb'
      }
    ]
  },
  {
    module: 'B0302-002',
    component: 'SCR01',
    parameter: 'UpperPanel',
    linings: [
      {
        id: 1,
        mw: 12.0,
        name: '3D-Standard MW 12'
      },
      {
        id: 2,
        mw: 20.0,
        name: '3D-Standard MW 20'
      },
      {
        id: 3,
        mw: 33.0,
        name: '3D-Standard MW 33'
      },
      {
        id: 4,
        mw: 45.0,
        name: '3D-Standard MW 45'
      },
      {
        id: 5,
        mw: 60.0,
        name: '3D-Standard MW 60'
      },
      {
        id: 6,
        mw: 80.0,
        name: '3D-Standard MW 80'
      },
      {
        id: 7,
        mw: 100.0,
        name: '3D-Standard MW 100'
      },
      {
        id: 8,
        mw: 125.0,
        name: '3D-Standard MW 125'
      },
      {
        id: 9,
        mw: 140.0,
        name: '3D-Standard MW 140'
      },
      {
        id: 10,
        mw: 0.0,
        name: 'Benutzerdefiniertes Sieb'
      },

    ]
  },
  {
    module: 'B0302-002',
    component: 'SCR01',
    parameter: 'LowerPanel',
    linings: [
      {
        id: 1,
        mw: 2.0,
        name: 'PU-Matte MW 2,0'
      },
      {
        id: 2,
        mw: 2.5,
        name: 'PU-Matte MW 2,5'
      },
      {
        id: 3,
        mw: 3.0,
        name: 'PU-Matte MW 3,0'
      },
      {
        id: 4,
        mw: 4.0,
        name: 'PU-Matte MW 4,0'
      },
      {
        id: 5,
        mw: 5.0,
        name: 'PU-Matte MW 5,0'
      },
      {
        id: 6,
        mw: 6.0,
        name: 'PU-Matte MW 6,0'
      },
      {
        id: 7,
        mw: 8.0,
        name: 'PU-Matte MW 8,0'
      },
      {
        id: 8,
        mw: 10.0,
        name: 'PU-Matte MW 10,0'
      },
      {
        id: 9,
        mw: 12.0,
        name: 'PU-Matte MW 12,0'
      },
      {
        id: 10,
        mw: 15.0,
        name: 'PU-Matte MW 15,0'
      },
      {
        id: 11,
        mw: 18.0,
        name: 'PU-Matte MW 18,0'
      },
      {
        id: 12,
        mw: 0.0,
        name: 'Benutzerdefiniertes Sieb'
      }
    ]
  },
  {
    module: 'B0303-001',
    component: 'SCR01',
    parameter: 'UpperPanel',
    linings: [
      {
        id: 1,
        mw: 12.0,
        name: '3D-Standard MW 12'
      },
      {
        id: 2,
        mw: 20.0,
        name: '3D-Standard MW 20'
      },
      {
        id: 3,
        mw: 33.0,
        name: '3D-Standard MW 33'
      },
      {
        id: 4,
        mw: 45.0,
        name: '3D-Standard MW 45'
      },
      {
        id: 5,
        mw: 60.0,
        name: '3D-Standard MW 60'
      },
      {
        id: 6,
        mw: 80.0,
        name: '3D-Standard MW 80'
      },
      {
        id: 7,
        mw: 100.0,
        name: '3D-Standard MW 100'
      },
      {
        id: 8,
        mw: 125.0,
        name: '3D-Standard MW 125'
      },
      {
        id: 9,
        mw: 140.0,
        name: '3D-Standard MW 140'
      },
      {
        id: 10,
        mw: 0.0,
        name: 'Stangenrost SPW 165'
      },
      {
        id: 11,
        mw: 0.0,
        name: 'Stangenrost SPW 115'
      },
      {
        id: 12,
        mw: 0.0,
        name: 'Benutzerdefiniertes Sieb'
      }

    ]
  },
  {
    module: 'B0303-001',
    component: 'SCR01',
    parameter: 'LowerPanel',
    linings: [
      {
        id: 1,
        mw: 12.0,
        name: '3D-Standard MW 12'
      },
      {
        id: 2,
        mw: 20.0,
        name: '3D-Standard MW 20'
      },
      {
        id: 3,
        mw: 33.0,
        name: '3D-Standard MW 33'
      },
      {
        id: 4,
        mw: 45.0,
        name: '3D-Standard MW 45'
      },
      {
        id: 5,
        mw: 60.0,
        name: '3D-Standard MW 60'
      },
      {
        id: 6,
        mw: 80.0,
        name: '3D-Standard MW 80'
      },
      {
        id: 7,
        mw: 100.0,
        name: '3D-Standard MW 100'
      },
      {
        id: 8,
        mw: 125.0,
        name: '3D-Standard MW 125'
      },
      {
        id: 9,
        mw: 140.0,
        name: '3D-Standard MW 140'
      },
      {
        id: 10,
        mw: 0.0,
        name: 'Stangenrost SPW 165'
      },
      {
        id: 11,
        mw: 0.0,
        name: 'Stangenrost SPW 115'
      },
      {
        id: 12,
        mw: 0.0,
        name: 'Benutzerdefiniertes Sieb'
      }

      ]
  },
  {
    module: 'B0304-001',
    component: 'SCR01',
    parameter: 'Panel',
    linings: [
      {
        id: 1,
        mw: 0.1,
        name: 'Drahtmasche MW 0,100'
      },
      {
        id: 2,
        mw: 0.125,
        name: 'Drahtmasche MW 0,125'
      },
      {
        id: 3,
        mw: .21,
        name: 'Drahtmasche MW 0,210'
      },
      {
        id: 4,
        mw: .25,
        name: 'Drahtmasche MW 0,250'
      },
      {
        id: 5,
        mw: .32,
        name: 'Drahtmasche MW 0,32'
      },
      {
        id: 6,
        mw: .5,
        name: 'Drahtmasche MW 0,500'
      },
      {
        id: 7,
        mw: .63,
        name: 'Drahtmasche MW 0,630'
      },
      {
        id: 8,
        mw: .8,
        name: 'Drahtmasche MW 0,800'
      },
      {
        id: 9,
        mw: 1.0,
        name: 'Drahtmasche MW 1,00'
      },
      {
        id: 10,
        mw: 1.250,
        name: 'Drahtmasche MW 1,25'
      },
      {
        id: 11,
        mw: 1.5,
        name: 'Drahtmasche MW 1,50'
      },
      {
        id: 12,
        mw: 1.6,
        name: 'Drahtmasche MW 1,60'
      },
      {
        id: 13,
        mw: 1.8,
        name: 'Drahtmasche MW 1,800'
      },
      {
        id: 14,
        mw: 2.0,
        name: 'Drahtmasche MW 2,00'
      },
      {
        id: 15,
        mw: 2.5,
        name: 'Drahtmasche MW 2,50'
      },
      {
        id: 16,
        mw: 3.15,
        name: 'Drahtmasche MW 3,15'
      },
      {
        id: 17,
        mw: 3.5,
        name: 'Drahtmasche MW 3,50'
      },
      {
        id: 18,
        mw: 4.0,
        name: 'Drahtmasche MW 4,00'
      },
      {
        id: 19,
        mw: 5.0,
        name: 'Drahtmasche MW 5,00'
      },
      {
        id: 20,
        mw: 6.3,
        name: 'Drahtmasche MW 6,30'
      },
      {
        id: 21,
        mw: 8.0,
        name: 'Drahtmasche MW 8,00'
      },
      {
        id: 22,
        mw: 10.0,
        name: 'Drahtmasche MW 10,00'
      },
      {
        id: 23,
        mw: 12.5,
        name: 'Drahtmasche MW 12,50'
      },
      {
        id: 24,
        mw: 16.0,
        name: 'Drahtmasche MW 16,00'
      },
      {
        id: 25,
        mw: 22.0,
        name: 'Drahtmasche MW 22,00'
      },
      {
        id: 26,
        mw: 30.0,
        name: 'Drahtmasche MW 30,00'
      },
      {
        id: 27,
        mw: 32.0,
        name: 'Drahtmasche MW 32,00'
      },
      {
        id: 28,
        mw: 40.0,
        name: 'Drahtmasche MW 40,00'
      },
      {
        id: 29,
        mw: 2.4,
        name: 'Drahtmasche MW 0,8x2,4'
      },
      {
        id: 30,
        mw: 3.1,
        name: 'Drahtmasche MW 1,0x3,0'
      },
      {
        id: 31,
        mw: .0,
        name: 'Benutzerdefiniertes Sieb'
      },
    ]
  },
  {
    module: 'B0304-001',
    component: 'SCR01',
    parameter: 'Panel2',
    linings: [
      {
        id: 1,
        mw: 0.1,
        name: 'Drahtmasche MW 0,100'
      },
      {
        id: 2,
        mw: 0.125,
        name: 'Drahtmasche MW 0,125'
      },
      {
        id: 3,
        mw: .21,
        name: 'Drahtmasche MW 0,210'
      },
      {
        id: 4,
        mw: .25,
        name: 'Drahtmasche MW 0,250'
      },
      {
        id: 5,
        mw: .32,
        name: 'Drahtmasche MW 0,32'
      },
      {
        id: 6,
        mw: .5,
        name: 'Drahtmasche MW 0,500'
      },
      {
        id: 7,
        mw: .63,
        name: 'Drahtmasche MW 0,630'
      },
      {
        id: 8,
        mw: .8,
        name: 'Drahtmasche MW 0,800'
      },
      {
        id: 9,
        mw: 1.0,
        name: 'Drahtmasche MW 1,00'
      },
      {
        id: 10,
        mw: 1.250,
        name: 'Drahtmasche MW 1,25'
      },
      {
        id: 11,
        mw: 1.5,
        name: 'Drahtmasche MW 1,50'
      },
      {
        id: 12,
        mw: 1.6,
        name: 'Drahtmasche MW 1,60'
      },
      {
        id: 13,
        mw: 1.8,
        name: 'Drahtmasche MW 1,800'
      },
      {
        id: 14,
        mw: 2.0,
        name: 'Drahtmasche MW 2,00'
      },
      {
        id: 15,
        mw: 2.5,
        name: 'Drahtmasche MW 2,50'
      },
      {
        id: 16,
        mw: 3.15,
        name: 'Drahtmasche MW 3,15'
      },
      {
        id: 17,
        mw: 3.5,
        name: 'Drahtmasche MW 3,50'
      },
      {
        id: 18,
        mw: 4.0,
        name: 'Drahtmasche MW 4,00'
      },
      {
        id: 19,
        mw: 5.0,
        name: 'Drahtmasche MW 5,00'
      },
      {
        id: 20,
        mw: 6.3,
        name: 'Drahtmasche MW 6,30'
      },
      {
        id: 21,
        mw: 8.0,
        name: 'Drahtmasche MW 8,00'
      },
      {
        id: 22,
        mw: 10.0,
        name: 'Drahtmasche MW 10,00'
      },
      {
        id: 23,
        mw: 12.5,
        name: 'Drahtmasche MW 12,50'
      },
      {
        id: 24,
        mw: 16.0,
        name: 'Drahtmasche MW 16,00'
      },
      {
        id: 25,
        mw: 22.0,
        name: 'Drahtmasche MW 22,00'
      },
      {
        id: 26,
        mw: 30.0,
        name: 'Drahtmasche MW 30,00'
      },
      {
        id: 27,
        mw: 32.0,
        name: 'Drahtmasche MW 32,00'
      },
      {
        id: 28,
        mw: 40.0,
        name: 'Drahtmasche MW 40,00'
      },
      {
        id: 29,
        mw: 2.4,
        name: 'Drahtmasche MW 0,8x2,4'
      },
      {
        id: 30,
        mw: 3.1,
        name: 'Drahtmasche MW 1,0x3,0'
      },
      {
        id: 31,
        mw: .0,
        name: 'Benutzerdefiniertes Sieb'
      },
    ]
  },
  {
    module: 'B0601-001',
    component: 'AJI01',
    parameter: 'Panel',
    linings: [
      {
        id: 1,
        mw: 0.2,
        name: 'Sinterbronze 0,2 mm'
      },
      {
        id: 2,
        mw: 0.15,
        name: 'Coniperf 0,15 mm'
      },
      {
        id: 3,
        mw: 0.3,
        name: 'Coniperf 0,3 mm'
      },
      {
        id: 4,
        mw: 0.6,
        name: 'Coniperf 0,6 mm'
      },
      {
        id: 5,
        mw: 0.9,
        name: 'Coniperf 0,9 mm'
      },
      {
        id: 6,
        mw: 1.2,
        name: 'Coniperf 1.2 mm'
      },
      {
        id: 7,
        mw: 2.0,
        name: 'Coniperf 2,0 mm'
      },
      {
        id: 8,
        mw: .0,
        name: 'Benutzerdefiniertes Sieb'
      },
    ]
  },
  {
    module: 'B0601-002',
    component: 'AJI01',
    parameter: 'Panel',
    linings: [
      {
        id: 1,
        mw: 0.2,
        name: 'Sinterbronze 0,2 mm'
      },
      {
        id: 2,
        mw: 0.15,
        name: 'Coniperf 0,15 mm'
      },
      {
        id: 3,
        mw: 0.3,
        name: 'Coniperf 0,3 mm'
      },
      {
        id: 4,
        mw: 0.6,
        name: 'Coniperf 0,6 mm'
      },
      {
        id: 5,
        mw: 0.9,
        name: 'Coniperf 0,9 mm'
      },
      {
        id: 6,
        mw: 1.2,
        name: 'Coniperf 1.2 mm'
      },
      {
        id: 7,
        mw: 2.0,
        name: 'Coniperf 2,0 mm'
      },
      {
        id: 8,
        mw: .0,
        name: 'Benutzerdefiniertes Sieb'
      },
    ]
  }
];
