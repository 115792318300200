
export const InitActionList: any[] = [
  {
    id: 1,
    action: 'ACTIONS.CONNECTWIFI',
    state: 0, // '0301-001-001-0001',
  },
  {
    id: 2,
    action: 'ACTIONS.CONNECTMODULE',
    state: 0, // '0301-001-001-0001',
  },
  {
    id: 3,
    action: 'ACTIONS.REQUESTDATA',
    state: 0, // '0301-001-001-0001',
  },
  {
    id: 4,
    action: 'ACTIONS.CONFIGURECLUSTER',
    state: 0, // '0301-001-001-0001',
  },
  {
    id: 5,
    action: 'ACTIONS.INITENV',
    state: 0, // '0301-001-001-0001',
  },
  {
    id: 6,
    action: 'ACTIONS.FINALCHECK',
    state: 0, // '0301-001-001-0001',
  }

];

export const InitClusterActionList: any[] = [
  {
    id: 1,
    action: 'ACTIONS.CONNECTWIFI',
    state: 0, // '0301-001-001-0001',
  },
  {
    id: 2,
    action: 'ACTIONS.CONFIGUREMASTER',
    state: 0, // '0301-001-001-0001',
  }
];
