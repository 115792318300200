import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {User} from '../classes/user';
import {Project} from '../classes/project';

@Injectable({
  providedIn: 'root'
})
export class CookieHandlingService {

  constructor(public cookieService: CookieService) { }

  public DeleteCurrentUser() {
    this.cookieService.delete('loggedUser');
  }

  public SetUserToken(token: string) {
    localStorage.setItem('bearertoken', token);
  }

  public RemoveCurrentConfig(usr: User) {
    if (usr) {
      try {
        this.cookieService.delete('lastproject' + usr.Id);
      } catch (e) {

      }
    }
  }

  public SetCurrentConfig(recipe: any, project: Project, valid: boolean, usr: User) {


    if (usr) {
      const savevalue = {
        id: project.id,
      };
      const today = new Date();
      today.setHours(today.getHours() + 6);
      try {
        this.cookieService.set('lastproject' + usr.Id, JSON.stringify(savevalue), today, null, null, false, 'Strict');
      } catch (e) {
        console.log(e);
      }

    }
  }

  public GetCurrentConfig(usr: User): any {
    if (usr) {
      const val = this.cookieService.get('lastproject' + usr.Id);

      if (val) {
        return JSON.parse(val);
      }
    }
    return null;
  }
}
