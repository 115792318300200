import {SetupTaskParameter} from './setup-task-parameter';

export class SetupTask {
  public Code: string;
  public DefaultText: string;
  public CodeText: string;
  public Text: string;
  public Done = false;
  public Prefix: SetupTaskParameter;
  public Surfix: SetupTaskParameter;
  public DefaultTask = false;

  public Clone(): SetupTask {
    const tsk = new SetupTask();
    tsk.DefaultTask = this.DefaultTask;
    tsk.Done = this.Done;
    tsk.Text = this.Text;
    tsk.Surfix = this.Surfix;
    tsk.CodeText = this.CodeText;
    tsk.Prefix = this.Prefix;
    tsk.Code = this.Code;
    tsk.DefaultText = this.DefaultText;
    return tsk;
  }

  public GetTranslationIdCode() {
    return 'MODULES.TASKS.CODES.' + this.Code;
  }

  public GetTranslationIdText() {
    return 'MODULES.TASKS.TEXT.' + this.Code;
  }

}
