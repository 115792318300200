export class SupportConnection {
  ModuleType: string;
  ModuleId: string;
  ModuleSerial: string;
  SequenceNumber: number;

  constructor(moduletype: string, moduleId: string, serial: string) {
    this.ModuleId = moduleId;
    this.ModuleSerial = serial;
    this.ModuleType = moduletype;
  }

}
