import {ModuleSyncActions} from './enums/module-sync-actions.enum';
import {ActionInitState} from './enums/action-init-state.enum';

export const ModuleSyncActionList: any[] = [
  {
    id: ModuleSyncActions.connectToWifi,
    action: 'ACTIONS.CONNECTWIFI',
    state: ActionInitState.open, // '0301-001-001-0001',
  },
  {
    id: ModuleSyncActions.getModuleInfo,
    action: 'ACTIONS.MODULESTATE',
    state: ActionInitState.open, // '0301-001-001-0001',
  }
];
