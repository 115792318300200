<app-layout>
  <app-header (sidenavToggle)="sidenav.toggle()" (optionToogle)="sideoption.toggle()"></app-header>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation" [ngClass]="{'sideNavBG': router.url !== '/live', 'darkGrayTheme': router.url === '/inventory' , 'grayTheme': router.url === '/live'}">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav #sideoption class="optionNav" position="end" role="navigation" [ngClass]="{'optionBG': router.url !== '/live', 'darkGrayTheme': router.url === '/inventory' ,'grayTheme': router.url === '/live'}">
      <app-option (sideoptionClose)="sideoption.close()"></app-option>
    </mat-sidenav>
    <mat-sidenav-content>
      <main>
        <router-outlet></router-outlet>
      </main>
      <div class="offlineError" *ngIf="connectivity.inOfflineMode && !connectivity.OfflineModePossible">
        <p class="hdError">{{'COMPONENTS.HOME.NOOFFLINEUSER' | i18next}}</p>
        <p class="hdError2">{{'COMPONENTS.HOME.NOOFFLINEUSER1' | i18next}}</p>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>

