import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuActionService {

  public OnNewProject = new EventEmitter();
  public OnLoadProject = new EventEmitter();
  public OnSaveProject = new EventEmitter();
  public OnShareProject = new EventEmitter();

  constructor() { }

  public NewProject() {
    this.OnNewProject.emit();
  }

  public LoadProject() {
    this.OnLoadProject.emit();
  }

  public SaveProject() {
    this.OnSaveProject.emit();
  }

  public ShareProject() {
    this.OnShareProject.emit();
  }

}
