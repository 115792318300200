<section fxLayout="row">
  <button mat-button (click)="goLeft()">
    <svg *ngIf="currentIndex>0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.5 16" width="15px">
      <defs>
        <style>.cls-1{fill:white;}</style>
      </defs>
      <title>Element 34</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Menu">
          <path class="cls-1" d="m 0.7700829,8.0979387 a 0.28,0.28 0 0 1 0,-0.25 L 4.3100824,0.6879393 4.9800821,1.0179383 1.5800824,7.9079387 a 0.15,0.15 0 0 0 0,0.13 l 3.3999997,6.8800003 -0.6699997,0.33 z"/>
        </g>
      </g>
    </svg>
  </button>
  <div *ngIf="_customerModules" class="menuCustomer"
       fxLayout="row wrap"
       fxLayout.lt-sm="column"
       fxLayoutGap="32px"
       fxLayoutAlign="flex-start"
  >
    <ng-container *ngFor="let md of displayCustomerModules">
      <div (click)="CustomerModuleClicked(md.CustomerModule)" class="item"
        fxFlex="0 1 calc(25% - 32px)"
        fxFlex.lt-md="0 1 calc(50% - 32px)"
        fxFlex.lt-sm="100%">
        <div fxLayout="column" >
          <div class="productImageContainer">
            <div class="counterDiv" *ngIf="md.Counter>1">
              <p class="counterObject">{{md.Counter}}</p>
            </div>
            <img class="productImage" src="{{md.CustomerModule.Module.PathIconSymboleBlack}}">
          </div>
          <p *ngIf="!md.CustomerModule.Module.Manufacturer">{{md.CustomerModule.Module.Group}} {{md.CustomerModule.Module.Name}}</p>
          <p *ngIf="md.CustomerModule.Module.Manufacturer">{{md.CustomerModule.Module.Manufacturer}} {{md.CustomerModule.Module.Model}}</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="_modules" class="menuCustomer"
       fxLayout="row wrap"
       fxLayout.lt-sm="column"
       fxLayoutGap="32px"
       fxLayoutAlign="flex-start"
  >
    <ng-container *ngFor="let md of displayModules">
      <div class="item"
           fxFlex="0 1 calc(25% - 32px)"
           fxFlex.lt-md="0 1 calc(50% - 32px)"
           fxFlex.lt-sm="100%">
        <div fxLayout="column" (click)="ModuleClicked(md)">
          <img class="productImage" src="{{md.PathIconSymboleBlack}}">
          <p *ngIf="!md.Manufacturer">{{md.Group}} {{md.Name}}</p>
          <p *ngIf="md.Manufacturer">{{md.Manufacturer}} {{md.Model}}</p>
        </div>

      </div>
    </ng-container>

  </div>
  <button mat-button  (click)="goRight()">
      <svg *ngIf="currentIndex+3 < GetCurrentArrayLength()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.5 16" width="15px">
        <defs>
          <style>.cls-1{fill:white;}</style>
        </defs>
        <title>Element 34</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path class="cls-1" d="m 4.9506316,7.8379396 a 0.28,0.28 0 0 1 0,0.25 L 1.4106321,15.247939 0.74063216,14.91794 4.1406321,8.0279396 a 0.15,0.15 0 0 0 0,-0.13 L 0.74063216,1.0179393 1.4106321,0.68793934 Z"/>
          </g>
        </g>
      </svg>
    </button>
</section>
