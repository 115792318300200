import {EventEmitter, Injectable} from '@angular/core';
import {User} from '../classes/user';
import {CookieHandlingService} from './cookie-handling.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Wards} from '../classes/wards';
import {BluectrlApiService} from './bluectrl-api.service';
import {Database} from 'dexie';
import {DatabaseService} from './database.service';
import {ConnectivityService} from './connectivity.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {


  currentUser: User;
  currentToken: string;
  public organisations: any[];
  public tokenHandler: any;
  public reconnectCounter = 0;

  public userLoggedOut = new EventEmitter<any>();
  public newUserLogin = new EventEmitter<User>();
  public ReconnectRequested = new EventEmitter<any>();
  public DataLoadingFinished = new EventEmitter();
  public DataLoaded = false;


  constructor(public cookiHandling: CookieHandlingService,
              public connectivity: ConnectivityService,
              private jwtHelper: JwtHelperService) {
    this.DataLoaded = false;
    const token = localStorage.getItem('bearertoken');
    this.connectivity.OnlineLoginRequested.subscribe(this.OnlineLoginRequested.bind(this));

    if (token) {
      // https://stackoverflow.com/questions/60758154/how-to-check-if-jwt-token-is-expired-in-angular-8
      if (!this.jwtHelper.isTokenExpired(token)) {
        // GET EXPIRATION DATE
        const decodedToken = this.jwtHelper.decodeToken(token);

        if (decodedToken.exp) {
          const date = new Date(0);
          date.setUTCSeconds(decodedToken.exp);
        }

        this.SetToken(token);
      } else {
        localStorage.removeItem('bearertoken');
        this.currentToken = null;
        this.userLoggedOut.emit();
      }
    }
  }

  private OnlineLoginRequested() {

    this.LogOut();
  }

  public GetCurrentUser() {

    if (this.currentUser) {
      return this.currentUser;
    } else {
      return this.currentUser;
    }
  }

  public OnOfflineUser(user: any) {
    if (user) {
      this.currentUser.OfflineUser = true;
      this.currentUser.Pin = user.Pin;
    } else {
      this.currentUser.OfflineUser = false;
    }
  }

  public SetDataLoaded() {
    this.DataLoaded = true;
    this.DataLoadingFinished.emit();
  }

  public getStoredUser(): string {
    return localStorage.getItem('user');
  }

  public LogOut() {
    if (this.connectivity.isOnline()) {
      if (this.connectivity.inOfflineMode) {
        this.connectivity.inOfflineMode = false;
      }
    }

    this.currentToken = null;
    localStorage.removeItem('bearertoken');
    localStorage.removeItem('user');
    localStorage.removeItem('password');
    this.currentUser = null;
    this.DataLoaded = false;
    this.reconnectCounter = 0;
    this.cookiHandling.DeleteCurrentUser();
    this.userLoggedOut.emit(this.currentUser);
  }

  public SetUser(user: string, pw: string) {
    localStorage.setItem('user', user);
    const comptPasword = btoa(pw);
    localStorage.setItem('password', comptPasword);
  }

  private TryReconnect() {
    clearInterval(this.tokenHandler);
    if (this.reconnectCounter >= 5) {
      this.LogOut();
    }
    const user = localStorage.getItem('user');
    if (user) {
      try {
        const pw = localStorage.getItem('password');
        if (pw) {
          const decPassword = atob(pw);
          // CONNECT
          this.reconnectCounter = this.reconnectCounter + 1;
          this.ReconnectRequested.emit({
            user: user,
            password: decPassword
          });
        } else {
          this.LogOut();
        }
      } catch (e) {
        this.LogOut();
      }
    } else {
      this.LogOut();
    }

  }

  public SetToken(token: string) {
    this.cookiHandling.SetUserToken(token);
    this.currentToken = token;
    this.RunTokenHandler();
  }

  public createUserFromResponse(data: any) {
    this.currentUser = new User(data.id, data.email, data.first_name, data.last_name, data.organization.name, data.organization.id, data.is_superuser);
    this.currentUser.Fullname = data.full_name;
    // ADD WARDS
    if (data.wards) {
      if (data.wards.length > 0) {
        for (const w of data.wards) {
          this.currentUser.AddWard(new Wards(w.id, w.name));
        }
      }
    }

    if (data.roles) {
      if (data.roles.length > 0) {
        for (const r of data.roles) {
          this.currentUser.AddRole(r.name, r.description);
        }
      }
    }

    // CHECK IF OFFLINE
    // this.database.getOfflineUser(this.currentUser.UserName);
    this.newUserLogin.emit(this.currentUser);
  }

  private RunTokenHandler() {
    if (this.tokenHandler) {
      clearInterval(this.tokenHandler);
    }

    this.tokenHandler = setInterval(() => {
      const token = localStorage.getItem('bearertoken');
      if (token) {
        // https://stackoverflow.com/questions/60758154/how-to-check-if-jwt-token-is-expired-in-angular-8
        if (this.jwtHelper.isTokenExpired(token)) {
          // LOGOUT
          this.TryReconnect();
        }
      }
    }, 60000);

  }
}
