export class ConveyorTypes {

  public Type: String;
  public Name: String;
  public TypeOfAddOn: number;

  constructor(type: string, name: string, typeOfAddOn: number) {
    this.Name = name;
    this.Type = type;
    this.TypeOfAddOn = typeOfAddOn;
  }
}
