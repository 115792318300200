import {Wards} from './wards';
import {UserRoles} from './user-roles';

export class User {

  Id: string;
  UserName: string;
  Login: Date;
  FirstName: string;
  LastName: string;
  Customer: string;
  CustomerId: string;
  rights = 'all';
  Fullname: string;
  IsSuperuser: boolean;
  UserWards: Wards[];
  Roles: UserRoles[];
  OfflineUser: boolean;
  Pin: string;

  constructor(Id: string, username: string, firstname: string, lastname: string, customer: string, customerId: string, superuser: boolean) {
    this.Id = Id;
    this.UserName = username;
    this.FirstName = firstname;
    this.LastName = lastname;
    this.Customer = customer;
    this.CustomerId = customerId;
    this.Fullname = firstname + ' ' + lastname;
    this.UserWards = [];
    this.IsSuperuser = superuser;
    this.Login = new Date(Date.now());
    this.Roles = [];
  }

  public AddWard(ward: Wards) {
    this.UserWards.push(ward);
  }

  public AddRole(name: string, description: string) {
    this.Roles.push(new UserRoles(name, description));
  }

  public isAdmin(): boolean {
    if (this.IsSuperuser) {
      return true;
    } else if (this.Roles.find(ex => ex.name === 'EDSAdmin' || ex.name === 'LTAdmin')) {
      return true;
    } else {
      return false;
    }
  }

}
