<section>
  <svg class="backimage" [attr.height]="GetSvgHeight()" [attr.width]="GetSvgWidth()">
    <g *ngFor="let pos of verticals">
      <path [attr.d]="GetPathVertical(pos)" stroke-width="1" stroke="white"></path>
    </g>
    <g *ngFor="let pos of horizontals">
      <path [attr.d]="GetPathHorizontal(pos)" stroke-width="1" stroke="white"></path>
    </g>
  </svg>
  <app-module-settings *ngIf="ShowModuleSettings" [SelectedModule]="moduleHandling.ActiveModule" [monitoringMsgId]="monitoringMsgId"></app-module-settings>
  <div class="designplate">
    <svg #svgGroup [attr.height]="GetSvgHeight()" [attr.width]="GetSvgWidth()">
    </svg>
  </div>
  <div class="designplate">
    <svg #borderbox [attr.width]="getInnerWidth()" [attr.height]="getInnerHeight()">
    </svg>
  </div>
  <div class="designplate" (click)="clickOnPlate($event)">
    <template #modulePlate>
    </template>
  </div>
  <div class="scrollIconsRight" *ngIf="ShowRight" (click)="GoRight()">
    <button mat-button class="scrollButton">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 60" width="30px" height="60px" fill="transparent">
        <title>Element 34</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path d="M 3.7880721,-0.10407654 26.137697,29.821693 3.7880721,60.126269" stroke="white" stroke-width="3px"/>
          </g>
        </g>
      </svg>
    </button>
  </div>
  <div class="scrollIconsLeft" *ngIf="ShowLeft"  (click)="GoLeft()">
    <button mat-button class="scrollButton">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 60" width="30px" height="60px" fill="transparent">
        <title>Element 34</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path d="M 26.137697,60 3.7880721,29.821693 26.137697,0.14846159"  stroke="white" stroke-width="3px"/>
          </g>
        </g>
      </svg>
    </button>
  </div>
  <div class="scrollIconsTop" *ngIf="ShowTop"  (click)="GoTop()">
    <button mat-button>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="60px" height="30px" fill="transparent">
          <title>Element 34</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Menu">
              <path d="M 0,22.423856 30.178307,7.271568 59.977807,22.423856" stroke="white" stroke-width="3px"/>
            </g>
          </g>
      </svg>
    </button>
  </div>
  <div class="scrollIconsBottom" *ngIf="ShowBottom"  (click)="GoBottom()">
    <button mat-button>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="60px" height="30px" fill="transparent">
        <title>Element 34</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Menu">
            <path d="M 0,7.271568 30.178307,22.423856 60.104077,7.271568" stroke="white" stroke-width="3px"/>
          </g>
        </g>

      </svg>
    </button>
  </div>
  <app-modulemenubar></app-modulemenubar>
  <app-module-catalog
    *ngIf="_width !== '0'"
    [ngStyle]="{'width': _width}"
                      [Visible]="_width !== '0'"
                      [currentModules]="GetUsedCustomerModules()"
                      (closeModuleSelection)="closeModuleCatalog()"
                      [activeModule]="moduleHandling.ActiveModule"
                      (OnCustomerModuleSelected)="CustomerModuleSelected($event)"
                      (OnModuleSelected)="ModuleSelected($event)"
                      (OnNotConnectedSelected)="SetNotConnected()"
                      (OnRemoveConnectionSelected)="RemoveConnection()"
                      [removeable]="isConveyorRemoveable()"
                      [selectedConveyor]="conveyorForModuleSelection"></app-module-catalog>
  <app-support-catalog *ngIf="_widthSupportCatalog !== '0'"
    [ngStyle]="{'width': _widthSupportCatalog}"

    [currentSupportModules]="GetUsedSupportModule()"
    (closeSupportModuleSelection)="closeSupportModuleCatalog()"
    [activeModule]="moduleHandling.ActiveModule"
    (OnCustomerSupportModuleSelected)="CustomerSupportModuleSelected($event)"
    (OnSupportModuleSelected)="SupportModuleSelected($event)"
    (OnNoSupportSelected)="SetNoSupportConnected()"
  >

  </app-support-catalog>

  <app-load-project [ShowLoad]="showLoadRecipe" *ngIf="showLoadRecipe" (CloseLoadProject)="CloseLoadRecipe($event)">
  </app-load-project>
</section>
