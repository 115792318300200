import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent} from '../components/views/home/home.component';
import {DesignViewComponent} from '../components/views/design-view/design-view.component';
import {LivePlateComponent} from '../components/views/live-plate/live-plate.component';
import {InventoryComponent} from '../components/views/inventory/inventory.component';
import {AdminViewComponent} from '../components/views/admin-view/admin-view.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'design', component: DesignViewComponent},
  { path: 'design/:token', component: DesignViewComponent},
  { path: 'live', component: LivePlateComponent},
  { path: 'admin', component: AdminViewComponent},
  { path: 'live/:token', component: LivePlateComponent},
  { path: 'inventory', component: InventoryComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '404', component: HomeComponent},
  { path: '**', component: HomeComponent}

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule {

  constructor() {
  }

}
