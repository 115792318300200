export enum StandaloneActions {
  ConnectModuleWifi = 1,
  ConnectToModule = 2,
  RequestData = 3,
  ConfigureModuleCluster = 4,
  InitializeEnviroment = 5,
  ModuleInitCheck = 6,
  ConnectToCluster = 7,
  ConfigureCluster = 8
}
