export class SimplePoint {

  constructor(public x: number, public y: number) {
  }

  public rotatePoint (angle: number, center: SimplePoint) {
    angle = (angle) * (Math.PI / 180);
    const rotX = Math.cos(angle) * (this.x - center.x) - Math.sin(angle) * (this.y - center.y) + center.x;
    const rotY = Math.sin(angle) * (this.x - center.x) + Math.cos(angle) * (this.y - center.y) + center.y;
    this.x = rotX;
    this.y = rotY;
  }
}
