export class Line {

  public point1: Point;
  public point2: Point;

  constructor(x1: number, y1: number, x2: number, y2: number) {
    this.point1 = new Point(x1, y1);
    this.point2 = new Point(x2, y2);
  }

  public intersects(b: Line): boolean {
    // a -> a.point1.X
    // b -> a.point1.Y
    // c -> a.point2.X
    // d -> a.point2.Y
    // p -> b.point1.X
    // q -> b.point1.Y
    // r -> b.point2.X
    // s -> b.point2.Y
    let det, gamma, lambda;
    det = (this.point2.X - this.point1.X) * (b.point2.Y - b.point1.Y) - (b.point2.X - b.point1.X) * (this.point2.Y - this.point1.Y);
    if (det === 0) {
      return false;
    } else {
      lambda = ((b.point2.Y - b.point1.Y) * (b.point2.X - this.point1.X) + (b.point1.X - b.point2.X) * (b.point2.Y - this.point1.Y)) / det;
      gamma = ((this.point1.Y - this.point2.Y) * (b.point2.X - this.point1.X) +
        (this.point2.X - this.point1.X) * (b.point2.Y - this.point1.Y)) / det;
      return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
    }
  }

  public rotateLine(degree: number, rotationPoint: Point) {
    this.point1.rotatePoint(degree, rotationPoint);
    this.point2.rotatePoint(degree, rotationPoint);
  }

}

export class Point {
  public X: number;
  public Y: number;

  constructor(x: number, y: number) {
    this.X = x;
    this.Y = y;
  }

  public rotatePoint(degree: number, rotationPoint: Point) {

    const ZP = new Point( this.X - rotationPoint.X,  this.Y - rotationPoint.Y);
    const readiantDegree = degree * (Math.PI / 180);
    const pointx = (Math.cos(readiantDegree) * ZP.X - (Math.sin(readiantDegree) * ZP.Y)) + rotationPoint.X;
    const pointy = (Math.sin(readiantDegree) * ZP.X + Math.cos(readiantDegree) * ZP.Y) + rotationPoint.Y;
    const newPoint = new Point(pointx, pointy);
    this.X = newPoint.X;
    this.Y = newPoint.Y;

  }
}
