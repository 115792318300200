import {StandaloneActions} from './enums/standalone-actions.enum';
import {ActionInitState} from './enums/action-init-state.enum';

export const StandaloneActionList: any[] = [
  {
    id: StandaloneActions.ConnectModuleWifi,
    action: 'ACTIONS.CONNECTWIFI',
    state: ActionInitState.open
  },
  {
    id: StandaloneActions.ConnectToModule,
    action: 'ACTIONS.CONNECTMODULE',
    state: ActionInitState.open
  },
  {
    id: StandaloneActions.RequestData,
    action: 'ACTIONS.REQUESTDATA',
    state: ActionInitState.open
  },
  {
    id: StandaloneActions.ConfigureModuleCluster,
    action: 'ACTIONS.CONFIGURECLUSTER',
    state: ActionInitState.open
  },
  {
    id: StandaloneActions.InitializeEnviroment,
    action: 'ACTIONS.INITENV',
    state: ActionInitState.open
  },
  {
    id: StandaloneActions.ModuleInitCheck,
    action: 'ACTIONS.FINALCHECK',
    state: ActionInitState.open
  },
  {
    id: StandaloneActions.ConnectToCluster,
    action: 'ACTIONS.CONNECTCLUSTER',
    state: ActionInitState.open
  },
  {
    id: StandaloneActions.ConfigureCluster,
    action: 'ACTIONS.CONFIGUREMASTER',
    state: ActionInitState.open
  }
];
