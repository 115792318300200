import {ModulePlan} from './module-plan';
import {SupportConnection} from './support-connection';
import {ModuleDefinition} from './module-definition';
import {CustomerModule} from './customer-module';

export class SupportModulePlan extends ModulePlan {
  private supportConnection: SupportConnection[];

  constructor(initial: boolean, modul?: ModuleDefinition, customermodule?: CustomerModule) {
    super(initial, modul, customermodule);
    this.supportConnection = [];
  }

  public haveModuleById(moduleId: string): boolean {
    if (this.supportConnection.length > 0) {
      const dt =  this.supportConnection.find(ex => ex.ModuleId === moduleId);

      if (dt) {
        return true;
      }
    }

    return false;
  }

  public haveModuleBySerial(serial: string): boolean {
    if (this.supportConnection.length > 0) {
      const dt =  this.supportConnection.find(ex => ex.ModuleSerial === serial);

      if (dt) {
        return true;
      }
    }

    return false;
  }

  public haveAvailableConnection(): boolean {
    return this.supportConnection.length < this.modul.MaximumConnections;
  }

  public getSupportModuleByConnectedId(moduleId: string): SupportModulePlan {
    if (this.haveModuleById(moduleId)) {
      return this;
    } else {
      return null;
    }
  }

  public getSupportModuleByConnectedSerial(serial: string): SupportModulePlan {
    if (this.haveModuleBySerial(serial)) {
      return this;
    } else {
      return null;
    }
  }

  public AddSupportNewConnection(modulePlan: ModulePlan): any {
    if (this.supportConnection.length >= this.modul.MaximumConnections) {
      return 'FULL';
    } else {
      this.supportConnection.push(new SupportConnection(modulePlan.modul.Type,
        modulePlan.customerModule.CustomerModuleId,
        modulePlan.customerModule.SerialNumber));
      return true;
    }
  }

  public removeConnection(modulePlan: ModulePlan) {
    if (this.haveModuleById(modulePlan.customerModule.CustomerModuleId)) {
      this.supportConnection = this.supportConnection.filter(ex => ex.ModuleId !== modulePlan.customerModule.CustomerModuleId);
    }
  }

  public haveConnections(): boolean {

    return this.supportConnection.length > 0;

  }

  public setModuleSequenceNumber(modulePlan: ModulePlan, sqno: number) {
    if (this.haveModuleById(modulePlan.customerModule.CustomerModuleId)) {
      const connection = this.supportConnection.find(ex => ex.ModuleId === modulePlan.customerModule.CustomerModuleId);

      if (connection) {
        connection.SequenceNumber = sqno;
      }
    }
  }

  public isLastModule(modulePlan: ModulePlan): boolean {
    if (this.supportConnection.filter(ex => ex.SequenceNumber < 0 ||
      (ex.SequenceNumber === null || ex.SequenceNumber === undefined)).length <= 0) {
      let maxSeqNo = -1;
      let customerModuleId = '';

      for (const con of this.supportConnection) {
        if (con.SequenceNumber > maxSeqNo) {
          maxSeqNo = con.SequenceNumber;
          customerModuleId = con.ModuleId;
        }
      }

      return customerModuleId === modulePlan.customerModule.CustomerModuleId;

    }

    return false;
  }

  public getActiveConnections(): SupportConnection[] {
    return this.supportConnection;
  }




}
