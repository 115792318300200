import {EventEmitter, Injectable} from '@angular/core';
import {CustomerModule} from '../classes/customer-module';
import {HttpClient} from '@angular/common/http';
import {ModuleSyncActions} from '../classes/enums/module-sync-actions.enum';
import {ActionInitState} from '../classes/enums/action-init-state.enum';
import {ModeSwitch} from '../classes/enums/mode-switch.enum';
import {ModuleInventoryInfo} from '../classes/module-inventory-info';
import {ModuleGlobalState} from '../classes/enums/module-global-state.enum';
import {environment} from '../../environments/environment';
import {ModulePlan} from '../classes/module-plan';
import {MatDialog} from '@angular/material/dialog';
import {MessageBoxComponent} from '../components/dialogs/message-box/message-box.component';
import {NetworkDevice} from '../classes/messaging/network-device';
import * as uuidV4 from 'uuid/v4';
import {ModuleConnections} from '../classes/enums/module-connections.enum';
import {ModuleHandlingService} from './module-handling.service';
import {ViewCode} from '../classes/enums/view-code.enum';
import {BluectrlTranslateService} from './bluectrl-translate.service';
import {ConfirmMessageBoxComponent} from '../components/dialogs/confirm-message-box/confirm-message-box.component';
import {WifiManagementService} from './v2/wifi-management.service';
import {ModuleOnboardingErrors} from '../classes/enums/module-onboarding-errors';
import {InitStateMessage} from '../classes/init-state-message';
import {WebsocketService} from './v2/websocket.service';
import {WebsocketConnectionErrors} from '../classes/enums/websocket-connection-errors';
import {WifiStates} from '../classes/enums/wifi-states';
import {ClusterOnboardingService} from './v2/cluster-onboarding.service';
import {ModuleOnboardingService} from './v2/module-onboarding.service';
import {Project} from '../classes/project';
import {MessageHandlingService} from './v2/message-handling.service';
import {OnboardingLogItem} from '../classes/onboarding-log-item';
import {OnboardingLogType} from '../classes/enums/onboarding-log-type';
import {DatabaseService} from './database.service';
import {NotificationEntry} from '../classes/notificationEntry';

@Injectable({
  providedIn: 'root'
})
export class ModuleServiceService {


  public ServiceModule: CustomerModule;
  public SyncActive = false;
  private CurrentModuleSyncSSID: string;
  private ModuleSyncStopRequested = false;
  private wifiChangeRetries = 0;
  private waitForWebsocketClosed = false;
  private wifiChangeRequested = false;
  private moduleSyncConnected = false;
  public ModuleSyncCancled = new EventEmitter();
  public ModuleSyncError = new EventEmitter<ModuleOnboardingErrors>();
  public ModuleSyncProgress = new EventEmitter<InitStateMessage>();
  public ModuleStateUpdated = new EventEmitter();
  public RequestModuleStateActive = false;
  public UpdateStateHandler: any;
  public ConfirmWifiHandler: any;
  public ModuleSyncState = new EventEmitter<any>();
  public NewModuleSyncStarted = new EventEmitter();
  private DisconnectWebsocketRequested = false;
  public AbortServiceMode = new EventEmitter();
  // STANDALONE INIT
  public Demo = false;
  public MasterSerialNumber: string;
  public CurrentClusterId: string;
  public MasterIp: string;
  public StandaloneModulePlan: ModulePlan;
  public StandaloneRecipeId: any;
  public StandaloneRecipe: any;
  public StandaloneProject: Project;
  public StandaloneClusterInitialized = false;
  public ClusterInitAlreadyRequested = false;
  public ModuleInitActive = false;
  public ClusterInitActive = false;
  public ClusterResetRequested = false;
  private ModuleInitResponseReceived = false;
  private moduleInitAlreadyRequested = false;
  private ClusterResetOngoing = false;
  private ModuleIsCurrentlyRunning = false;
  private WaitForModuleStop = false;
  private InitModuleClusterConfigResponseReceived = false;


  constructor(
    private http: HttpClient,
    public wifi: WifiManagementService,
    public websocket: WebsocketService,
    public moduleHandling: ModuleHandlingService,
    public translate: BluectrlTranslateService,
    public clusterOnboarding: ClusterOnboardingService,
    public moduleOnboarding: ModuleOnboardingService,
    public messageHandling: MessageHandlingService,
    public dialog: MatDialog,
    public database: DatabaseService
  ) {

    this.websocket.WebsocketClosed.subscribe(this.onWebsocketClosed.bind(this));
    this.websocket.WebsocketError.subscribe(this.onWebsocketError.bind(this));
    this.websocket.WebsocketConnected.subscribe(this.onWebsocketConnected.bind(this));
    this.websocket.Disconnected.subscribe(this.onWebsocketDisconnected.bind(this));
    this.websocket.ConnectionError.subscribe(this.onWebsocketConnectionError.bind(this));

    this.wifi.WifiChangeError.subscribe(this.onWifiChangeError.bind(this));
    this.wifi.WifiChanged.subscribe(this.onWifiChanged.bind(this));
    this.messageHandling.ModuleModeChanged.subscribe(this.UpdateModeSwitch.bind(this));
    this.messageHandling.EmergencyTestStateChanged.subscribe(this.EmergencyTestRequiredChanged.bind(this));
    this.messageHandling.NotificationsReceived.subscribe(this.UpdateNotifications.bind(this));
    this.moduleOnboarding.StandaloneModuleOnboardingFinished.subscribe(this.onStandaloneModuleInitDone.bind(this));
    this.moduleHandling.OnViewModeChanged.subscribe(this.viewModeChange.bind(this));
  }

  // MODULE SYNC PROCESSwifi
  private viewModeChange(viewMode: ViewCode) {
    if (viewMode !== ViewCode.inventory) {
      this.RemoveConnectionInfo();
      this.MasterIp = null;
      this.CurrentClusterId = null;
      this.MasterSerialNumber = null;
    }
  }

  private onWebsocketClosed() {
    if (this.SyncActive && !this.isCancled()) {

    }
    if (this.StandaloneClusterInitialized === true) {

      const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOWIFI');
      const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOWIFI');
      this.dialog.open(ConfirmMessageBoxComponent,
        {panelClass: 'panelclass', data: {header: msg, content: content}});
      this.RemoveConnectionInfo();
    }
  }

  private onWebsocketError() {
    if (this.SyncActive && !this.isCancled()) {

    }
    if (this.StandaloneClusterInitialized === true) {

      const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOWIFI');
      const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOWIFI');
      this.dialog.open(ConfirmMessageBoxComponent,
        {panelClass: 'panelclass', data: {header: msg, content: content}});
      this.RemoveConnectionInfo();
    }
  }

  private onWebsocketDisconnected() {
    if (this.SyncActive && !this.isCancled()) {
      if (this.waitForWebsocketClosed) {
        this.waitForWebsocketClosed = false;
        this.CheckCurrentWifi();
      }
    } else if (this.ModuleInitActive === true && this.DisconnectWebsocketRequested) {
      this.DisconnectWebsocketRequested = false;
      this.moduleOnboarding.StartStandaloneOnbording(this.StandaloneModulePlan,
        this.StandaloneRecipe,
        this.CurrentClusterId,
        this.StandaloneRecipeId);
    } else if (this.StandaloneClusterInitialized === true) {
      this.RemoveConnectionInfo();
    }
  }

  private onWebsocketConnected() {
    if (this.SyncActive && !this.isCancled()) {

    }
  }

  private onWebsocketConnectionError(error: WebsocketConnectionErrors) {
    if (this.SyncActive && !this.isCancled()) {

    }
  }

  private onWifiChanged(ssid: string) {
    if (this.SyncActive && !this.isCancled()) {
      if (this.wifiChangeRequested) {
        // SUCCESS
        this.wifiChangeRequested = false;
        if (this.CurrentModuleSyncSSID === this.wifi.getCurrentSSID()) {
          this.sendProgress(ModuleSyncActions.connectToWifi, ActionInitState.success);
          this.GetModuleStateInfo();
        } else {
          this.rechangeWifi();
        }
      }
    }
  }

  private rechangeWifi() {
    this.wifiChangeRetries += 1;
    if (this.wifiChangeRetries < 7) {
      this.wifiChangeRequested = true;
      this.wifi.changeWifi(this.CurrentModuleSyncSSID, environment.DefaultModuleWifiKey);
    } else {
      // ERROR
      this.sendProgress(ModuleSyncActions.connectToWifi, ActionInitState.wifiSkip);
    }
  }

  private onWifiChangeError(state: WifiStates) {
    if (this.SyncActive && !this.isCancled()) {
      if (this.wifiChangeRequested) {
        // FAILED RETRY
        this.wifiChangeRequested = false;
        this.rechangeWifi();
      }
    }
  }

  private stopModuleSyncOnError(action: ModuleSyncActions, forced = false) {
    if (this.SyncActive && !this.isCancled()) {
      this.sendProgress(action, ActionInitState.failed, forced);
      this.AbortModuleSyncProcess();
    }
  }

  private resetModuleSyncParameters() {
    this.SyncActive = false;
    this.wifiChangeRetries = 0;
    this.ModuleSyncStopRequested = false;
    this.waitForWebsocketClosed = false;
    this.wifiChangeRequested = false;
  }

  private AbortModuleSyncProcess() {
    this.resetModuleSyncParameters();
    this.ServiceModule = null;
    this.CurrentModuleSyncSSID = null;
    this.moduleSyncConnected = false;
    if (this.websocket.IsWebsocketConnected()) {
      this.websocket.Disconnect();
    }
    this.ModuleSyncCancled.emit();
  }

  private isCancled(): boolean {
    if (this.ModuleSyncStopRequested) {
      // STOP IT
      this.AbortModuleSyncProcess();
      return true;
    } else {
      return false;
    }
  }

  private sendProgress(action: ModuleSyncActions, state: ActionInitState, forced: boolean = false) {
    if (this.SyncActive) {
      this.ModuleSyncProgress.emit(new InitStateMessage(action, state, forced));
    }
  }

  public SetCustomerModule(module: CustomerModule): boolean {
    if (this.SyncActive) {
      return false;
    } else {

      if (this.clusterOnboarding.ClusterInitialized) {
        this.clusterOnboarding.resetOnboarding();
      }

      // STOP ALL INITS
      this.RemoveConnectionInfo();
      this.MasterIp = null;
      this.CurrentClusterId = null;
      this.MasterSerialNumber = null;

      this.ServiceModule = module;
      this.CurrentModuleSyncSSID = environment.ModuleWifiSurFix + this.ServiceModule.SerialNumber;
      this.moduleSyncConnected = false;
      if (this.UpdateStateHandler) {
        clearInterval(this.UpdateStateHandler);
      }
    }
  }

  public RemoveConnectionInfo() {
    if (this.ConfirmWifiHandler) {
      clearInterval(this.ConfirmWifiHandler);
    }
    this.resetModuleSyncParameters();
    this.ModuleInitActive = false;
    this.moduleInitAlreadyRequested = false;
    this.ClusterInitActive = false;
    this.ClusterResetOngoing = false;
    this.ClusterResetRequested = false;
    this.ModuleIsCurrentlyRunning = false;
    this.WaitForModuleStop = false;
    this.ModuleInitResponseReceived = false;
    this.InitModuleClusterConfigResponseReceived = false;
    this.StandaloneClusterInitialized = false;
    this.ClusterInitAlreadyRequested = false;
    if (this.ServiceModule) {
      // this.ServiceModule.ModuleSSID = null;
      this.ServiceModule.InventoryInfo = null;
      this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Unknown;
      this.ServiceModule.Module.NetworkInfo = null;
      this.ServiceModule.Module.DisableServiceMode();
    }

    this.DisconnectWebsocketRequested = false;
    this.AbortServiceMode.emit();
  }

  public StartSync(): boolean {
    if (this.ServiceModule) {
      if (!this.SyncActive) {
        this.NewModuleSyncStarted.emit();
        this.SyncActive = true;
        this.CheckCurrentWifi();

      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public CheckCurrentWifi() {

    if (this.websocket.IsWebsocketConnected()) {
      this.waitForWebsocketClosed = true;
      this.websocket.Disconnect();
    } else {

      if (this.wifi.ServiceAvailable) {

        if (this.wifi.getCurrentSSID() === this.ServiceModule.ModuleSSID) {
          // IS SSID
          this.sendProgress(ModuleSyncActions.connectToWifi, ActionInitState.success);
          this.GetModuleStateInfo();

        } else {
          this.wifiChangeRequested = true;
          this.wifi.changeWifi(this.CurrentModuleSyncSSID, environment.DefaultModuleWifiKey);
        }
      } else {
        this.sendProgress(ModuleSyncActions.connectToWifi, ActionInitState.wifiSkip);
      }
    }
  }

  public continueWithoutWifiService() {
    if (this.SyncActive && !this.isCancled()) {
      if (this.wifi.getCurrentSSID() === this.ServiceModule.ModuleSSID) {
        this.GetModuleStateInfo();
      } else {
        this.stopModuleSyncOnError(ModuleSyncActions.connectToWifi);
      }
    }
  }

  private GetModuleStateInfo() {
    if (!this.RequestModuleStateActive) {
      this.RequestModuleStateActive = true;
      // GET MODULE INFO
      const hndl = setTimeout(() => {
        try {

          return this.http.get(environment.DefaultModuleApi, {
            responseType: 'json',
            observe: 'response',
          }).subscribe((datax: any) => {
            clearTimeout(hndl);

            if (datax.body) {

              let moduleinfo = datax.body;

              if (datax.body.Data) {
                moduleinfo = datax.body.Data;
              }

              // SET STATE
              if (moduleinfo.SerialNumber) {
                if (moduleinfo.SerialNumber === this.ServiceModule.SerialNumber) {
                  // this.ServiceModule.
                  let switchMode = ModeSwitch.NONE;

                  switch (moduleinfo.SwitchMode) {
                    case 'service':
                      switchMode = ModeSwitch.SERVICE;
                      break;
                    case 'automatic':
                      switchMode = ModeSwitch.AUTOMATIC;
                      break;
                  }

                  //SET INVENTORY INFO

                  const inventoryInfo = new ModuleInventoryInfo(moduleinfo.ClusterIP,
                    moduleinfo.ClusterId,
                    moduleinfo.RecipeId,
                    switchMode,
                    moduleinfo.MasterSerial,
                    moduleinfo.MasterIP);
                  this.ServiceModule.InventoryInfo = inventoryInfo;

                  if (inventoryInfo.ClusterId.startsWith('service') || inventoryInfo.ClusterId.startsWith('temp-')) {
                    inventoryInfo.ClusterId = null;
                    inventoryInfo.RecipeId = null;
                  }

                  if (inventoryInfo.RecipeId) {
                    this.database.recipes.toArray().then(rec => {
                      const recs = rec.find(ex => ex.id === inventoryInfo.RecipeId);
                      if (recs) {
                        console.log('found recipe');
                        console.log(recs);
                        inventoryInfo.RecipeName = recs.name;
                      }
                    });
                  }

                  if (this.ServiceModule.InventoryInfo.SwitchMode === ModeSwitch.SERVICE && this.ServiceModule.InventoryInfo.ClusterId) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.ClusterService;
                    this.ServiceModule.Module.EnableServiceMode();
                  } else if (this.ServiceModule.InventoryInfo.SwitchMode === ModeSwitch.SERVICE) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Service;
                    this.ServiceModule.Module.EnableServiceMode();
                  } else if (this.ServiceModule.InventoryInfo.ClusterId) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Cluster;
                    this.ServiceModule.Module.DisableServiceMode();
                  } else {
                    this.ServiceModule.Module.DisableServiceMode();
                    // this.ServiceModule.ModuleGlobalState = ModuleGlobalState.NoState;
                  }




                  this.RequestModuleStateActive = false;
                  this.sendProgress(ModuleSyncActions.getModuleInfo, ActionInitState.success);
                  this.SyncActive = false;
                  this.moduleSyncConnected = true;
                  this.StartUpdateHandler();
                } else {
                  clearTimeout(hndl);
                  this.stopModuleSyncOnError(ModuleSyncActions.getModuleInfo);
                }

              } else {
                clearTimeout(hndl);
                this.stopModuleSyncOnError(ModuleSyncActions.getModuleInfo);
              }
            } else {

              // SET STATE
              if (datax.SerialNumber) {
                if (datax.SerialNumber === this.ServiceModule.SerialNumber) {
                  // this.ServiceModule.
                  let switchMode = ModeSwitch.NONE;

                  switch (datax.SwitchMode) {
                    case 'service':
                      switchMode = ModeSwitch.SERVICE;
                      break;
                    case 'automatic':
                      switchMode = ModeSwitch.AUTOMATIC;
                      break;
                  }
                  this.ServiceModule.InventoryInfo = new ModuleInventoryInfo(datax.ClusterIP,
                    datax.ClusterId,
                    datax.RecipeId,
                    switchMode,
                    datax.MasterSerial,
                    datax.MasterIP);

                  if (this.ServiceModule.InventoryInfo.RecipeId) {
                    this.database.recipes.toArray().then(rec => {
                      const recs = rec.find(ex => ex.id === this.ServiceModule.InventoryInfo.RecipeId);
                      if (recs) {
                        console.log('found recipe');
                        console.log(recs);
                        this.ServiceModule.InventoryInfo.RecipeName = recs.name;
                      }
                    });
                  }

                  if (this.ServiceModule.InventoryInfo.SwitchMode === ModeSwitch.SERVICE && this.ServiceModule.InventoryInfo.ClusterId) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.ClusterService;
                    this.ServiceModule.Module.EnableServiceMode();
                  } else if (this.ServiceModule.InventoryInfo.SwitchMode === ModeSwitch.SERVICE) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Service;
                    this.ServiceModule.Module.EnableServiceMode();
                  } else if (this.ServiceModule.InventoryInfo.ClusterId) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Cluster;
                    this.ServiceModule.Module.DisableServiceMode();
                  } else {
                    this.ServiceModule.Module.DisableServiceMode();
                    // this.ServiceModule.ModuleGlobalState = ModuleGlobalState.NoState;
                  }

                  this.RequestModuleStateActive = false;
                  this.sendProgress(ModuleSyncActions.getModuleInfo, ActionInitState.success);
                  this.moduleSyncConnected = true;
                  this.StartUpdateHandler();
                  this.SyncActive = false;
                } else {
                  clearTimeout(hndl);
                  this.stopModuleSyncOnError(ModuleSyncActions.getModuleInfo);
                }

              } else {
                clearTimeout(hndl);
                this.stopModuleSyncOnError(ModuleSyncActions.getModuleInfo);
              }
            }

          }, () => {
            clearTimeout(hndl);
            this.stopModuleSyncOnError(ModuleSyncActions.getModuleInfo);
          });
        } catch {
          clearTimeout(hndl);
          this.stopModuleSyncOnError(ModuleSyncActions.getModuleInfo);
        }
      }, 4000);
    }
  }

  private StartUpdateHandler() {

    if (this.UpdateStateHandler) {
      clearInterval(this.UpdateStateHandler);
    }

    this.UpdateStateHandler = setInterval(() => {
      try {

        if (this.SyncActive || this.RequestModuleStateActive) {
          clearInterval(this.UpdateStateHandler);
        } else if (this.moduleSyncConnected) {

          this.http.get(environment.DefaultModuleApi, {
            responseType: 'json',
            observe: 'response',
          }).subscribe((datax: any) => {
            if (datax.body) {
              // SET STATE
              let moduleinfo = datax.body;

              if (datax.body.Data) {
                moduleinfo = datax.body.Data;
              }


              if (moduleinfo.SerialNumber) {
                if (moduleinfo.SerialNumber === this.ServiceModule.SerialNumber) {
                  // this.ServiceModule.
                  let switchMode = ModeSwitch.NONE;

                  switch (moduleinfo.SwitchMode) {
                    case 'service':
                      switchMode = ModeSwitch.SERVICE;
                      break;
                    case 'automatic':
                      switchMode = ModeSwitch.AUTOMATIC;
                      break;
                  }
                  const inventoryInfo = new ModuleInventoryInfo(moduleinfo.ClusterIP,
                    moduleinfo.ClusterId,
                    moduleinfo.RecipeId,
                    switchMode,
                    moduleinfo.MasterSerial,
                    moduleinfo.MasterIP);
                  this.ServiceModule.InventoryInfo = inventoryInfo;

                  if (inventoryInfo.ClusterId.startsWith('service') || inventoryInfo.ClusterId.startsWith('temp-')) {
                    inventoryInfo.ClusterId = null;
                    inventoryInfo.RecipeId = null;
                  }

                  if (inventoryInfo.RecipeId) {
                    this.database.recipes.toArray().then(rec => {
                      const recs = rec.find(ex => ex.id === inventoryInfo.RecipeId);
                      if (recs) {
                        console.log('found recipe');
                        console.log(recs);
                        inventoryInfo.RecipeName = recs.name;
                      }
                    });
                  }

                  if (this.ServiceModule.InventoryInfo.SwitchMode === ModeSwitch.SERVICE && this.ServiceModule.InventoryInfo.ClusterId) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.ClusterService;
                    this.ServiceModule.Module.EnableServiceMode();
                  } else if (this.ServiceModule.InventoryInfo.SwitchMode === ModeSwitch.SERVICE) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Service;
                    this.ServiceModule.Module.EnableServiceMode();
                  } else if (this.ServiceModule.InventoryInfo.ClusterId) {
                    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Cluster;
                    this.ServiceModule.Module.DisableServiceMode();
                  } else {
                    this.ServiceModule.Module.DisableServiceMode();
                    // this.ServiceModule.ModuleGlobalState = ModuleGlobalState.NoState;
                  }
                  this.ModuleStateUpdated.emit();
                } else {
                  this.NoState();
                }

              } else {
                this.NoState();
              }
            } else {
            // SET STATE
            if (datax.SerialNumber) {
              if (datax.Service === this.ServiceModule.SerialNumber) {
                // this.ServiceModule.
                let switchMode = ModeSwitch.NONE;

                switch (datax.SwitchMode) {
                  case 'service':
                    switchMode = ModeSwitch.SERVICE;
                    break;
                  case 'automatic':
                    switchMode = ModeSwitch.AUTOMATIC;
                    break;
                }
                this.ServiceModule.InventoryInfo = new ModuleInventoryInfo(datax.ClusterIP,
                  datax.ClusterId,
                  datax.RecipeId,
                  switchMode,
                  datax.MasterSerial,
                  datax.MasterIP);

                if (this.ServiceModule.InventoryInfo.RecipeId) {
                  this.database.recipes.toArray().then(rec => {
                    const recs = rec.find(ex => ex.id === this.ServiceModule.InventoryInfo.RecipeId);
                    if (recs) {
                      console.log('found recipe');
                      console.log(recs);
                      this.ServiceModule.InventoryInfo.RecipeName = recs.name;
                    }
                  });
                }

                if (this.ServiceModule.InventoryInfo.SwitchMode === ModeSwitch.SERVICE && this.ServiceModule.InventoryInfo.ClusterId) {
                  this.ServiceModule.ModuleGlobalState = ModuleGlobalState.ClusterService;
                } else if (this.ServiceModule.InventoryInfo.SwitchMode === ModeSwitch.SERVICE) {
                  this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Service;
                } else if (this.ServiceModule.InventoryInfo.ClusterId) {
                  this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Cluster;
                } else {
                  // this.ServiceModule.ModuleGlobalState = ModuleGlobalState.NoState;
                }
                this.ModuleStateUpdated.emit();
              } else {
                this.NoState();
              }

            } else {
              this.NoState();
            }

          }


          }, () => {
            this.NoState();
          });
        }
      } catch {
        this.NoState();
      }

    }, 20000);
  }

  public AbortModuleStateUpdate() {
    if (this.UpdateStateHandler) {
      this.moduleSyncConnected = false;
      clearInterval(this.UpdateStateHandler);
    }
  }

  public AbortModuleStateSync() {
    if (this.SyncActive) {
      this.SyncActive = false;
      this.RequestModuleStateActive = false;
    }
  }

  private NoState() {
    this.ServiceModule.InventoryInfo = null;
    this.ServiceModule.ModuleGlobalState = ModuleGlobalState.Unknown;
    this.ModuleStateUpdated.emit();
    this.moduleSyncConnected = false;
    clearInterval(this.UpdateStateHandler);
  }

  public CreateStandaloneRecipe(moduleplan: ModulePlan): string {

    const constellation = [];
    const configuration: string[] = [];
    const composition = [];
    const connection = [];

    let configStr = '[';

    const sqno = 0;

    const initmd = moduleplan;

    if (initmd !== null && initmd !== undefined) {
      const dd = {
        moduletype: initmd.modul.Type,
        moduleversion: initmd.modul.Version,
        modulename: initmd.modul.Group + ' ' + initmd.modul.Name,
        seqno: sqno,
        outputs: [
        ]
      };

      let dds = '{' +
        '"moduletype": "' + initmd.modul.Type + '",' +
        '"moduleversion": "' + initmd.modul.Version + '",' +
        '"modulename": "' + initmd.modul.Group + ' ' + initmd.modul.Name + '",' +
        '"seqno": "' + sqno + '",' +
        '"outputs": [';


      constellation.push(initmd.rotation);

      // CONFIG

      let obj = '{';

      const moddevs = initmd.modul.Components.filter(ex => ex.Configs.length > 0);

      for (let d = 0; d < moddevs.length; d++ ) {
        const mdconf = moddevs[d].Configs.filter(ex => (!ex.Mode || ex.Mode !== 'Service') && ex.Excluded === false);

        if (mdconf.length > 0) {
          let vls = '"' + moddevs[d].PlcKey + '": {';

          for (let i = 0; i < mdconf.length; i++) {
            vls = vls + '"' + mdconf[i].Name + '": ' + mdconf[i].getValue();
            if ( i < mdconf.length - 1) {
              vls = vls + ',';
            }
          }
          vls = vls + '}';
          obj = obj + vls;
          if (d < moddevs.length - 1) {
            obj = obj + ',';
          }
        }
      }
      obj = obj + '}';
      configuration.push(obj);
      configStr += obj + ',';


      if (initmd.modul.NetworkInfo) {
        connection.push({
          node: initmd.modul.NetworkInfo.ipaddress,
          plc: '',
          pose: '',
        });
      } else if (initmd.customerModule) {
        if (initmd.customerModule.NodeIpAddress) {
          connection.push({
            node: initmd.customerModule.NodeIpAddress,
            plc: '',
            pose: '',
          });
        } else {
          connection.push(null);
        }
      } else {
        connection.push(null);
      }

      if (initmd.customerModule !== null && initmd.customerModule !== undefined) {
        if (initmd.customerModule.SerialNumberSetted || initmd.customerModule.SerialNumber) {
          composition.push(initmd.customerModule.SerialNumber);
        } else {
          composition.push('');
        }
      } else {
        composition.push('');
      }
      const outputtoname = [];

      for (const outp of initmd.modul.ConveyorBelts) {
        let connectionName = '';

        if (outp.ConnectionPoint === ModuleConnections.center) {
          connectionName = 'center';
        } else if (outp.ConnectionPoint === ModuleConnections.left) {
          connectionName = 'left';
        } else if (outp.ConnectionPoint === ModuleConnections.right) {
          connectionName = 'right';
        } else if (outp.ConnectionPoint === ModuleConnections.right_3) {
          connectionName = 'right_1';
        } else if (outp.ConnectionPoint === ModuleConnections.left_3) {
          connectionName = 'left_1';
        } else if (outp.ConnectionPoint === ModuleConnections.right_1) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right_3);
          if (conn) {
            connectionName = 'right_2';
          } else {
            connectionName = 'right_1';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.right_2) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right_3);
          if (conn) {
            connectionName = 'right_3';
          } else {
            connectionName = 'right_2';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.left_1) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left_3);
          if (conn) {
            connectionName = 'left_2';
          } else {
            connectionName = 'left_1';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.left_2) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left_3);
          if (conn) {
            connectionName = 'left_3';
          } else {
            connectionName = 'left_2';
          }
        }

        outputtoname.push({
          outputName: outp.OutputName,
          position: connectionName,
          connectionInfo: 'notconnected'});
      }

      for (const outp of outputtoname) {
          dd.outputs.push('"' + outp.position + '": "' + outp.connectionInfo + '"');
          dds += '{"' + outp.position + '": "' + outp.connectionInfo + '"},';
      }

      if (outputtoname.length <= 0) {
        dds = dds + ']';
      } else {
        dds = dds.substring(0, dds.length - 1) + ']';
      }

      // dds = dds.substring(0, dds.length - 1) + ']';

      configStr = '[';

      for (const str of configuration) {
        configStr += str + ',';
      }

      console.log('{"tree":' + dds + '}' +
        ', "constellation": ' + JSON.stringify(constellation) +
        ', "configuration": ' + configStr.substring(0, configStr.length - 1) + ']' +
        ', "composition": ' + JSON.stringify(composition) +
        ', "connection": ' + JSON.stringify(connection) +
        '}');

      return '{"tree":' + dds + '}' +
        ', "constellation": ' + JSON.stringify(constellation) +
        ', "configuration": ' + configStr.substring(0, configStr.length - 1) + ']' +
        ', "composition": ' + JSON.stringify(composition) +
        ', "connection": ' + JSON.stringify(connection) +
        '}';
    }



    return '';
  }

  public CreateStandaloneModulePlan(): ModulePlan {
    if (this.ServiceModule) {
      const mdlplan = new ModulePlan(true, null, this.ServiceModule);
      for (const connections of this.ServiceModule.Module.ConveyorBelts) {
        mdlplan.addConntection(null, connections.ConnectionPoint, true);
      }
      // CHECK Configuration
      for (const confComp of mdlplan.modul.Components.filter(ex => ex.Configs.length > 0)) {
        for (const param of confComp.Configs) {
          if (param.ConstallationDependency !== null && param.DesignRelevant === true) {
            if (param.CurrentValue === null || param.CurrentValue === undefined) {
              if (param.MinValue !== null && param.MinValue !== undefined) {
                param.SetValue(param.MinValue);
              } else {
                param.CurrentValue = 0;
              }
            }
          }
        }
      }

      this.StandaloneModulePlan = mdlplan;
      return mdlplan;
    }



    return null;
  }

  public StartModuleInit() {
    console.log('start module init');
    if (this.StandaloneModulePlan) {
      if (this.UpdateStateHandler) {
        clearInterval(this.UpdateStateHandler);
      }

      if (this.ModuleInitActive === false) {
        // this.RemoveConnectionInfo();
        this.ModuleInitActive = true;
        this.moduleInitAlreadyRequested = false;
        let uuid = uuidV4();
        uuid = uuid.toString().substring(0, 8);
        this.CurrentClusterId = 'service-' + uuid;
        this.StandaloneRecipe = JSON.parse(this.CreateStandaloneRecipe(this.StandaloneModulePlan));
        this.StandaloneRecipeId = uuidV4();
        if (this.websocket.IsWebsocketConnected()) {
          this.DisconnectWebsocketRequested = true;
          this.websocket.Disconnect();
        } else {

          this.moduleOnboarding.currentMasterModule = null;
          this.moduleOnboarding.StartStandaloneOnbording(this.StandaloneModulePlan,
            this.StandaloneRecipe,
            this.CurrentClusterId,
            this.StandaloneRecipeId);
        }
      }
    }
  }

  public cancelOnboarding() {
    if (this.moduleOnboarding.OnboardingRunning) {
      // CONNECT TO EXISTING CLUSTER

      this.StandaloneClusterInitialized = false;
      this.StandaloneModulePlan = null;
      this.StandaloneProject = null;
      this.StandaloneRecipeId = null;
      this.moduleOnboarding.CancelOnboarding();

    } else if (this.clusterOnboarding.OnboardingRunning) {
      // CONNECT TO EXISTING CLUSTER
      this.clusterOnboarding.CancelOnboarding();
      this.StandaloneClusterInitialized = false;
      this.StandaloneModulePlan = null;
      this.StandaloneProject = null;
      this.StandaloneRecipeId = null;

    }

    console.log('cancel onboarding')
    this.ModuleInitActive = false;
    this.RemoveConnectionInfo();

  }

  private onStandaloneModuleInitDone(data: any) {
    console.log('stand alone done');
    this.CurrentClusterId = data.ClusterId;
    this.MasterIp = data.MasterIpAddress;
    this.MasterSerialNumber = data.MasterSerialNumber;
    console.log('set master info');
    console.log(this.CurrentClusterId);
    console.log(this.MasterIp);
    console.log(this.MasterSerialNumber);
  }

  public RetryClusterOnboarding() {
    this.ClusterInitActive = false;
    this.StandaloneClusterInitialized = false;
    if (this.clusterOnboarding.OnboardingRunning) {
      this.clusterOnboarding.CancelOnboarding();
    }
    this.finalizeStandaloneModuleOnboarding();
  }

  public finalizeStandaloneModuleOnboarding() {
    this.ModuleInitActive = false;
    this.moduleInitAlreadyRequested = false;
    if (!this.StandaloneModulePlan.ConfigFinishedSended) {
      this.messageHandling.sendModuleInitFinished(uuidV4(), this.StandaloneModulePlan.customerModule.SerialNumber);
      this.StandaloneModulePlan.ConfigFinishedSended = true;
    }
    this.StandaloneModulePlan.customerModule.FullyConfigured = true;
    setTimeout(() => {
      if (!this.ClusterInitActive) {
        // CREATE STANDALONE PROJECT
        this.ClusterInitActive = true;
        this.StandaloneProject = new Project('service');
        this.StandaloneProject.Modules.push(this.StandaloneModulePlan);
        // START CLUSTER ONBOARDING
        this.clusterOnboarding.startStandaloneClusterOnboarding(this.MasterIp,
          this.MasterSerialNumber,
          this.CurrentClusterId,
          this.StandaloneProject);
      }
    }, 3000);
  }

  public finalizeStandaloneClusterOnboarding() {
    if (this.ClusterInitActive) {
      this.ClusterInitActive = false;
      this.StandaloneClusterInitialized = true;
      this.CheckWifiForCurrentCluster();
    }
  }

  public CheckWifiForCurrentCluster() {
    this.ConfirmWifiHandler = setInterval(async () => {
      if (this.moduleHandling.CurrentViewMode === ViewCode.inventory && this.StandaloneClusterInitialized === true) {

        const splitter = this.CurrentClusterId.split('-');

        // LOAD CURRENT SSID
        const curres = this.wifi.getCurrentSSID();
        if (curres === null) {
          // this.RemoveConnectionInfo();
        } else {
          if (curres !== environment.ClusterWifiSurFix +  splitter[splitter.length - 1]) {
            const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOWIFI');
            const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOWIFI');
            this.dialog.open(ConfirmMessageBoxComponent,
              {panelClass: 'panelclass', data: {header: msg, content: content}});
            this.RemoveConnectionInfo();
          }
        }
      } else {
        clearInterval(this.ConfirmWifiHandler);
      }
    }, 5000);
  }

  private UpdateModeSwitch(msg: any) {
    if (this.StandaloneModulePlan.customerModule.SerialNumber === msg.ctxId) {
      if (msg.state !== 'service' && this.moduleHandling.CurrentViewMode === ViewCode.inventory) {
        const dialogRef1 = this.dialog.open(MessageBoxComponent,
          {
            width: 200 + 'px',
            panelClass: 'panelclass',
            data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOTINSERVICE')
          });
        dialogRef1.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
        this.ServiceModule.Module.DisableServiceMode();
        this.RemoveConnectionInfo();
      } else if (msg.state === 'service' && this.moduleHandling.CurrentViewMode === ViewCode.inventory) {
        if (msg.state === 'service') {
          this.ServiceModule.Module.EnableServiceMode();
          // this.StandaloneModulePlan.modul.CurrentMode = ModeSwitch.SERVICE;
        } else if (msg.state === 'crane') {
          this.ServiceModule.Module.DisableServiceMode();
          this.StandaloneModulePlan.modul.CurrentMode = ModeSwitch.CRANE;
        } else {
          this.ServiceModule.Module.DisableServiceMode();
          this.StandaloneModulePlan.modul.CurrentMode = ModeSwitch.AUTOMATIC;
        }
      }
    }
  }

  private EmergencyTestRequiredChanged(msg: any) {
    if (this.StandaloneModulePlan.customerModule.SerialNumber === msg.ctxId) {
      if (this.StandaloneModulePlan.customerModule.Module.NetworkInfo) {
        this.StandaloneModulePlan.customerModule.Module.NetworkInfo.emergencystoprequired = msg.state;
        this.StandaloneModulePlan.customerModule.Module.EmergencyStopRequired = msg.state;
      }
    }
  }

  public UpdateNotifications(newNotifications: any) {

    console.log('got notification');

    if (this.StandaloneClusterInitialized) {
      if (this.StandaloneProject && this.StandaloneProject.Modules) {
        if (newNotifications.module) {

          const mds = this.StandaloneProject.Modules.filter(ex => ex.customerModule);
          if (mds) {
            if (newNotifications.module.serialnumber) {
              const md = mds.find(ex => ex.customerModule.SerialNumber === newNotifications.module.serialnumber);
              if (md) {

                console.log('got module for notification');
                md.customerModule.ActiveWarningNotifications = [];
                md.customerModule.ActiveErrorNotifications = [];

                for (const noti of newNotifications.module.notifications) {
                  if (noti.context === 'module') {
                    if (noti.type === 1) { // ERROR
                      // FIND Module:
                      const cmp = md.modul.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');
                      if (cmp) {
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');
                        // const notis = cmp.Errors.find(ex => ex.Index === noti.code);
                        if (notis) {
                          console.log('add module error ');

                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();

                          console.log(notis);

                          md.customerModule.ActiveErrorNotifications.push(notis);
                        }
                      }
                    } else if (noti.type === 2) { // WARNING
                      // FIND Module:
                      const cmp = md.modul.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');
                      if (cmp) {
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                        // const notis = cmp.Warnings.find(ex => ex.Index === noti.code);
                        if (notis) {
                          console.log('add module warning ');

                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();

                          console.log(notis);

                          md.customerModule.ActiveWarningNotifications.push(notis);
                        }
                      }
                    }
                  } else if (noti.context === 'device') {
                    if (noti.type === 1) { // ERROR
                      // FIND Module:


                      const cmp = md.modul.Components.find(ex => ex.PlcKey === noti.ctxId);
                      if (cmp) {
                        if (noti.code !== null && noti.code !== undefined) {
                          const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');
                          // const notis = cmp.Errors.find(ex => ex.Index === noti.code);
                          if (notis) {

                            console.log('add device error ');

                            notis.Component = cmp.PlcKey;
                            notis.ComponentTranslateId = cmp.GetTranslationId();

                            console.log(notis);

                            md.customerModule.ActiveErrorNotifications.push(notis);
                          }
                        } else if (noti.foreignCode !== null && noti.foreignCode !== undefined) {
                          // const notis = cmp.Errors.find(ex => ex.Index.toString() === noti.foreignCode);
                          const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.foreignCode, 'Error');
                          if (notis) {

                            console.log('add device error ');

                            notis.Component = cmp.PlcKey;
                            notis.ComponentTranslateId = cmp.GetTranslationId();

                            console.log(notis);

                            md.customerModule.ActiveErrorNotifications.push(notis);
                          }
                        }


                      }
                    } else if (noti.type === 2) { // WARNING
                      // FIND Module:
                      const cmp = md.modul.Components.find(ex => ex.PlcKey === noti.ctxId);
                      if (cmp) {
                        if (noti.code !== null && noti.code !== undefined) {
                          const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                          // const notis = cmp.Warnings.find(ex => ex.Index === noti.code);
                          if (notis) {

                            console.log('add device warn ');

                            notis.Component = cmp.PlcKey;
                            notis.ComponentTranslateId = cmp.GetTranslationId();

                            console.log(notis);

                            md.customerModule.ActiveWarningNotifications.push(notis);
                          }
                        } else if (noti.foreignCode !== null && noti.foreignCode !== undefined) {
                          // const notis = cmp.Warnings.find(ex => ex.Index.toString() === noti.foreignCode);
                          const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.foreignCode, 'Warning');
                          if (notis) {

                            console.log('add device warn ');

                            notis.Component = cmp.PlcKey;
                            notis.ComponentTranslateId = cmp.GetTranslationId();

                            console.log(notis);

                            md.customerModule.ActiveWarningNotifications.push(notis);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (this.StandaloneProject && this.StandaloneProject.SupportModules) {
        if (newNotifications.module) {

          const mds = this.StandaloneProject.SupportModules.filter(ex => ex.customerModule);
          if (mds) {
            if (newNotifications.module.serialnumber) {
              const md = mds.find(ex => ex.customerModule.SerialNumber === newNotifications.module.serialnumber);
              if (md) {

                md.customerModule.ActiveWarningNotifications = [];
                md.customerModule.ActiveErrorNotifications = [];

                for (const noti of newNotifications.module.notifications) {
                  if (noti.context === 'module') {
                    if (noti.type === 1) { // ERROR
                      // FIND Module:
                      const cmp = md.modul.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');
                      if (cmp) {
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');
                        // const notis = cmp.Errors.find(ex => ex.Index === noti.code);
                        if (notis) {
                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();
                          md.customerModule.ActiveErrorNotifications.push(notis);
                        }
                      }
                    } else if (noti.type === 2) { // WARNING
                      // FIND Module:
                      const cmp = md.modul.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');
                      if (cmp) {
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                        // const notis = cmp.Warnings.find(ex => ex.Index === noti.code);
                        if (notis) {
                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();
                          md.customerModule.ActiveWarningNotifications.push(notis);
                        }
                      }
                    }
                  } else if (noti.context === 'device') {
                    if (noti.type === 1) { // ERROR
                      // FIND Module:


                      const cmp = md.modul.Components.find(ex => ex.PlcKey === noti.ctxId);
                      if (cmp) {
                        if (noti.code !== null && noti.code !== undefined) {
                          const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');
                          // const notis = cmp.Errors.find(ex => ex.Index === noti.code);
                          if (notis) {
                            notis.Component = cmp.PlcKey;
                            notis.ComponentTranslateId = cmp.GetTranslationId();
                            md.customerModule.ActiveErrorNotifications.push(notis);
                          }
                        } else if (noti.foreignCode !== null && noti.foreignCode !== undefined) {
                          // const notis = cmp.Errors.find(ex => ex.Index.toString() === noti.foreignCode);
                          const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.foreignCode, 'Error');
                          if (notis) {
                            notis.Component = cmp.PlcKey;
                            notis.ComponentTranslateId = cmp.GetTranslationId();
                            md.customerModule.ActiveErrorNotifications.push(notis);
                          }
                        }


                      }
                    } else if (noti.type === 2) { // WARNING
                      // FIND Module:
                      const cmp = md.modul.Components.find(ex => ex.PlcKey === noti.ctxId);
                      if (cmp) {
                        if (noti.code !== null && noti.code !== undefined) {
                          const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                          // const notis = cmp.Warnings.find(ex => ex.Index === noti.code);
                          if (notis) {
                            notis.Component = cmp.PlcKey;
                            notis.ComponentTranslateId = cmp.GetTranslationId();
                            md.customerModule.ActiveWarningNotifications.push(notis);
                          }
                        } else if (noti.foreignCode !== null && noti.foreignCode !== undefined) {
                          const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.foreignCode, 'Warning');
                          // const notis = cmp.Warnings.find(ex => ex.Index.toString() === noti.foreignCode);
                          if (notis) {
                            notis.Component = cmp.PlcKey;
                            notis.ComponentTranslateId = cmp.GetTranslationId();
                            md.customerModule.ActiveWarningNotifications.push(notis);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }



  }

}
