<section fxLayout="column" fxFlexAlign="center" *ngIf="ShowBar">
  <div fxLayout="row" fxFlexAlign="center" fxLayoutGap="5px" class="flex-container">
    <button mat-button (click)="OpenSerialSelection()">
      Module Setup
    </button>
    <button mat-button (click)="OpenInitCluster()">
      Init Cluster
    </button>
    <button mat-button (click)="openModuleSettings()">
      Module Settings
    </button>
    <button mat-button (click)="openModuleState()">
      Module State
    </button>
    <button mat-button (click)="openSupportModuleState()">
      Support Module State
    </button>
  </div>
</section>
