export class NotificationEntry {
  public Index: string;
  // public TranslateIDNumber;
  public TranslateIDText;
  public Bmk;
  public TextNumber: string;
  public Text: string;
  public Component: string;
  public ComponentTranslateId: string;
  private module: string;
  public type: string;

  constructor(module: string, component: string, index: string, type: string) {
    this.Index = index;
    this.TextNumber = (type === 'Error' ? 'E' : type == "Warning" ? 'W' : 'I') + index;
    this.TranslateIDText = 'MODULES.NOTIFICATION.' + this.TextNumber;

    // this.TranslateIDNumber =  'MODULES.NOTIFICATIONS.' + module.toUpperCase() + '.' + component.toUpperCase() + '.' + type.toUpperCase() + 'S.CODES.' + index.toString();
    // this.TranslateIDText = 'MODULES.NOTIFICATIONS.' + module.toUpperCase() + '.' + component.toUpperCase() + '.' + type.toUpperCase() + 'S.TEXT.' + index.toString();
    // this.Bmk = 'MODULES.NOTIFICATIONS.' + module.toUpperCase() +
    //   '.' + component.toUpperCase() +
    //   '.' + type.toUpperCase() +
    //   'S.BMK.' + index.toString();


    this.TextNumber = index.toString();
    this.Component = component;
    this.Text = 'undefined';
    this.module = module;
    this.type = type;
  }

  public Copy(): NotificationEntry {
    const not = new NotificationEntry( this.module, this.Component, this.Index, this.type);
    // not.TranslateIDNumber = this.TranslateIDNumber;
    not.TranslateIDText = this.TranslateIDText;
    not.TextNumber = this.TextNumber;
    not.Text = this.Text;
    not.Component = this.Component;
    // not.Bmk = this.Bmk;
    not.ComponentTranslateId = this.ComponentTranslateId;
    return not;
  }
}
