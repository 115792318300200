import {OnboardingLogType} from './enums/onboarding-log-type';

export class OnboardingLogItem {

  public Text: string;
  public LogType: OnboardingLogType;

  constructor(text: string, type: OnboardingLogType) {
    this.Text = text;
    this.LogType = type;
  }

}
