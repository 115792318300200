export class Feature {
  public Name: string;
  public Required: boolean;
  public MinValue: number;
  public MaxValue: number;
  public StepSize: number;
  public DefaultValue: number;
  public ConstellationDependency = false;
  public DirectionDependency = false;
  public Visible = true;
  public ShowPositiveOnly = false;
  public Virtual = false;
}
