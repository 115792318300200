import {InternalBeltState} from '../internal-belt-state';

export class NetworkDevice {
  public serialnumber: string;
  public ipaddress: string;
  public revision: number;
  public statemodule: number;
  public switchstate: string;
  public emergencystoprequired: boolean;

  public emergencystopok: boolean;
  public belts: InternalBeltState[];
  public levellingrequired: boolean;
  public nodeversion: string;
  public specversion: string;
  public plcversion: string;
  public additionalModuleInformations: any;

  constructor(serialNumber: string,
              ipAddress: string,
              revision: number,
              statemodule: number,
              switchstate: string,
              beltState: InternalBeltState[],
              emergencystoprequired: boolean,
              emergencyStopOk: boolean,
              levellingrequired: boolean,
              nodeverion: string,
              plcversion: string,
              specversion: string) {
    this.serialnumber = serialNumber;
    this.ipaddress = ipAddress;
    this.revision = revision;
    this.belts = beltState;
    this.switchstate = switchstate;
    this.statemodule = statemodule;
    this.levellingrequired = levellingrequired;
    this.emergencystoprequired = emergencystoprequired;
    if (emergencyStopOk != undefined) {
      this.emergencystopok = emergencyStopOk;
    } else {
      this.emergencystopok = true;
    }

    this.plcversion = plcversion;
    this.nodeversion = nodeverion;
    this.specversion = specversion;
  }
}
