import {Line, Point} from './line';

export class LineCollider {

  public Lines: Line[];
  public svgElement: SVGPathElement;
  public path: string;

  constructor(svgelement: SVGPathElement) {
    // DESTRUCT
    this.svgElement = svgelement;
    this.path = svgelement.getAttribute('d');
    this.Lines = [];
    if (this.path) {
      const elements = this.path.split(' ');

      if (elements.length > 1) {

        for (let i = 0; i < elements.length; i++) {
          let current = elements[i];
          let next = '';


          if (i === elements.length - 1 && elements[i].indexOf('z') >= 0) {
            next = elements[0];
          } else if (i < elements.length - 1) {
            next = elements[i + 1];
          } else {
            break;
          }

          if (current.indexOf('M') >= 0) {
            current = current.replace('M', '');
          } else if (current.indexOf('m') >= 0) {
            current = current.replace('m', '');
          } else if (current.indexOf('Z') >= 0) {
            current = current.replace('Z', '');
          } else if (current.indexOf('z') >= 0) {
            current = current.replace('z', '');
          }

          if (next.indexOf('M') >= 0) {
            next = next.replace('M', '');
          } else if (next.indexOf('m') >= 0) {
            next = next.replace('m', '');
          } else if (next.indexOf('Z') >= 0) {
            next = next.replace('Z', '');
          } else if (next.indexOf('z') >= 0) {
            next = next.replace('z', '');
          }
          // START POINT
          const startArray = current.split(',');
          if (startArray.length > 1) {
            const startx = Number(startArray[0]);
            const starty = Number(startArray[1]);
            // END POINT
            const endArray = next.split(',');
            if (endArray.length > 1) {
              const endx = Number(endArray[0]);
              const endy = Number(endArray[1]);

              if (startx !== endx || starty !== endy) {
                this.Lines.push(new Line(startx, starty, endx, endy));
              }
            }
          }
        }
      }
    }

    // ROTATE

    if (this.svgElement.hasAttribute('transform')) {
      const transformation = this.svgElement.getAttribute('transform');

      if (transformation) {
        if (transformation.indexOf('rotate') >= 0) {
          const strIndex = transformation.indexOf('rotate(');
          const endIndex = transformation.indexOf(')', strIndex);

          let angle = 0;
          let midx = 0;
          let midy = 0;

          const subStr = transformation.substring(strIndex + 7, transformation.length - 1);



          const splitted = subStr.split(',');

          if (splitted.length > 2) {
            angle = Number(splitted[0]);
            midx = Number(splitted[1]);
            midy = Number(splitted[2]);
          } else {
            angle = Number(splitted[0]);
          }

          for (const l of this.Lines) {
            l.rotateLine(angle, new Point(midx, midy));
          }


        }
      }

    }

  }

  public getResolutionSVG(): string {
    let result = 'M';

    for (const l of this.Lines) {
      result += l.point1.X.toString() + ',' + l.point1.Y.toString() + ' ';
    }
    result += 'z';




    return result;


  }

  public collidesWith(other: LineCollider): boolean {

    for (const l of this.Lines) {
      for (const lo of other.Lines) {
        if (l.intersects(lo)) {
          return true;
        }
      }
    }
    return false;
  }



}
