<div class="main" fxLayout="column">
  <mat-dialog-content>
    <h2 fxFlexAlign="center" mat-dialog-title>{{'DIALOGS.RULENOTIFICATION.TITLE' | i18next }}</h2>
    <div fxLayout="column">
      <p class="content">{{data.TriggerComponent | i18next}} - {{data.TriggerParameter | i18next}} -> {{data.TriggerValue}}</p>
      <p class="content">{{'DIALOGS.RULENOTIFICATION.REQUIRES' | i18next }}</p>
      <p class="content" *ngIf="data.TargetValue">{{data.TargetComponent | i18next}} - {{data.TargetParameter | i18next}} -> {{data.TargetValue}}</p>
      <p class="content" *ngIf="data.ActionName">{{data.TargetComponent | i18next}} - {{data.TargetParameter | i18next}} -> {{data.ActionName}}</p>
    </div>
  </mat-dialog-content>

  <div class="buttondiv" fxLayout="row">
    <button class="yesButton" fxFlexAlign="flex-start" (click)="Change()">{{'COMMON.CHANGE' | i18next }}</button>
    <button class="noButton" fxFlexAlign="flex-end" (click)="Cancel()">{{'COMMON.CANCEL' | i18next }}</button>
  </div>
</div>
