export class ComponentFeature {
  public Name: string;
  public Required: boolean;
  public Available: boolean;
  public Context: string;

  constructor(name: string, required: boolean, context: string) {
    this.Name = name;
    this.Context = context;

    if (!required) {
      required = false;
    }

    this.Required = required;
  }

  public Copy(): ComponentFeature {
    const ft = new ComponentFeature(this.Name, this.Required, this.Context);
    ft.Available = this.Available;
    return ft;
  }
}
