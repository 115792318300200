<section fxLayout="column">
  <div fxLayout="row" class="moduleheader">
    <p class="headerText">{{Group}}</p>
    <svg class="updownicon" *ngIf="!OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 4.58 9.16" (click)="OwnVisible = true">
      <defs>
        <style>.cls-1{fill:#0077ba;}</style>
      </defs>
      <title>aufklappen</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 9.16 0 0 4.58 4.58 0 9.16"/>
        </g>
      </g>
    </svg>
    <svg class="updownicon" *ngIf="OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 9.16 4.58" (click)="OwnVisible = false">
      <defs>
        <style>.cls-1{fill:#0077ba;}</style>
      </defs>
      <title>zuklappen</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 0 9.16 0 4.58 4.58 0 0"/>
        </g>
      </g>
    </svg>
  </div>
  <div class="line"></div>
  <div fxLayout="row" *ngIf="OwnVisible" class="containerforrow">
    <!-- ELEMENTS !-->
    <div class="menuCustomer"
         fxLayout="row wrap"
         fxLayout.lt-sm="column"
         fxLayoutGap="10px"
         fxLayoutAlign="flex-start"
    >
      <ng-container *ngFor="let md of _modules">
        <div class="item"  [ngClass]="{'itemOnline': md.Module.NetworkInfo, 'itemService': md.Module.NetworkInfo && md.Module.NetworkInfo.switchstate === 'service'}"
             fxFlex="0 1 calc(50% - 100px)"
             fxFlex.lt-md="0 1 calc(50% - 100px)"
             fxFlex.lt-sm="100%">
          <app-iventory-module-element [customerModule]="md" (ShowSettings)="CustomerModuleClicked($event)" (ShowDetailView)="emitShowDetailView($event)"></app-iventory-module-element>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="line"></div>
</section>
