export class InitStateMessage {
  public ActionId: number;
  public State: number;
  public Forced: boolean;

  constructor(action: number, state: number, forced = false) {
    this.ActionId = action;
    this.State = state;
    this.Forced = forced;
  }
}
