import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  public isVisible = true;
  public visibilityChanged = new EventEmitter<boolean>();

  constructor() {

    document.addEventListener('visibilitychange', (evt) => {


      if (!document.hidden) {
        this.isVisible = true;
        this.visibilityChanged.emit(this.isVisible);
      } else {
        this.isVisible = false;
        this.visibilityChanged.emit(this.isVisible);
      }

      // const v = 'visible';
      // const h = 'hidden';
      // const evtMap = {
      //     focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h
      //   };
      //
      // evt = evt || window.event;
      // if (evt.type in evtMap) {
      //   console.log('visible');
      // } else {
      //   console.log('hidden');
      // }
    });
  }
}
