import {EventEmitter, Injectable} from '@angular/core';
import {WebsocketConnectionErrors} from '../../classes/enums/websocket-connection-errors';
import {MessageTypes} from '../../classes/enums/message-types.enum';
import {ContextTypes} from '../../classes/enums/context-types.enum';
import {Entities} from '../../classes/enums/entities.enum';
import * as uuidV4 from 'uuid/v4';

import {BluectrlTranslateService} from '../bluectrl-translate.service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {MessageBoxComponent} from '../../components/dialogs/message-box/message-box.component';
import {OnboardingLogItem} from '../../classes/onboarding-log-item';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket: WebSocket;
  private heartbeatHandler: any;
  private timeoutHandler: any;
  private defaultPort = '8085';
  private IsConnected = false;
  private CurrentIp: string;
  private CurrentConnection: string;
  private waitForConnect = false;
  private systemDisconnect = false;
  private autoReconnectActive = false;
  private reconnects = 0;
  private hasNetworkConnection = true;
  private hasInternetAccess = true;
  private DisconnectTimeout = false;
  public ConnectionError = new EventEmitter<WebsocketConnectionErrors>();
  public WebsocketConnected = new EventEmitter();
  public WebsocketClosed = new EventEmitter();
  public WebsocketError = new EventEmitter<any>();
  public WebsocketMessage = new EventEmitter<any>();
  public Disconnected = new EventEmitter();
  public NewLogMessage = new EventEmitter<OnboardingLogItem>();


  constructor(
  public translate: BluectrlTranslateService,
  public dialog: MatDialog) {

  }

  public isInternetConnectionOn(): boolean {
    return true;
    //return this.hasInternetAccess;
  }



  public IsWebsocketConnected(): boolean {
    return this.IsConnected;
  }

  public GetCurrentIP(): string {
    return this.CurrentIp;
  }

  public ConnectToSecuredServer(url: string, forceClose = false) {
    if (environment.demo === true) {
      // ERROR NOT IMPLEMENTED
      const dialogRef = this.dialog.open(MessageBoxComponent,
        {
          width: 200 + 'px',
          panelClass: 'panelclass',
          data: {text: this.translate.GetTranslation('MESSAGEBOX.CONTENT.SERVICENOTAVAILABLE'), timeout: 1000}
        });
      dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});

    } else {
      this.reconnects = 0;
      this.CurrentIp = url;
      this.DisconnectTimeout = false;
      this.CurrentConnection = 'wss://' + url + ':' + this.defaultPort;
      this.prepareForConnection(forceClose);
    }
  }

  public ConnectToServer(url: string, forceClose = false) {
    if (environment.demo === true) {
      // ERROR NOT IMPLEMENTED
      const dialogRef = this.dialog.open(MessageBoxComponent,
        {
          width: 200 + 'px',
          panelClass: 'panelclass',
          data: {text: this.translate.GetTranslation('MESSAGEBOX.CONTENT.SERVICENOTAVAILABLE'), timeout: 1000}
        });
      dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});

    } else {
      this.reconnects = 0;
      this.CurrentIp = url;
      this.DisconnectTimeout = false;
      this.CurrentConnection = 'ws://' + url + ':' + this.defaultPort;
      this.prepareForConnection(forceClose);
    }
  }

  public Disconnect() {
    this.autoReconnectActive = false;
    this.waitForConnect = false;

    if (this.IsConnected) {
      if (this.socket) {
        this.systemDisconnect = true;
        if (this.heartbeatHandler) {
          clearInterval(this.heartbeatHandler);
        }
        this.socket.close();
        setTimeout(() => {
          if (this.systemDisconnect === true && this.IsConnected) {
            this.IsConnected = false;
            this.systemDisconnect = false;
            this.socket = null;
            this.Disconnected.emit();
          }
        }, 5000);
        // this.socket.close();
      } else {
        this.IsConnected = false;
        this.systemDisconnect = false;
        this.socket = null;
        this.Disconnected.emit();
      }
    } else {
      this.Disconnected.emit();
    }
  }

  private prepareForConnection(forceClose = false) {

    this.waitForConnect = false;
    this.autoReconnectActive = false;
    if (this.IsConnected) {
      if (forceClose) {
        this.waitForConnect = true;
        if (this.socket) {
          if (this.socket.readyState === this.socket.OPEN) {
            // NOW CLOSING
            this.autoReconnectActive = false;
            this.socket.close();
          }
        } else {
          this.socket = null;
          this.IsConnected = false;
          this.prepareForConnection(forceClose);
        }

      } else {
        this.ConnectionError.emit(WebsocketConnectionErrors.CurrentlyConnected);
      }
    } else {

      try {
        this.socket = new WebSocket(this.CurrentConnection);
        this.socket.onopen = () => this.websocketConnectionOpened();
        this.socket.onclose = () => this.websocketConnectionClosed();
        this.socket.onerror = (error) => this.websocketConnectionError(error);
        this.socket.onmessage = (message) => this.webSocketOnMessage(message);

        if (!this.autoReconnectActive || this.reconnects === 3) {
          this.timeoutHandler = setTimeout(() => {
            if (!this.IsConnected) {
              if (!this.waitForConnect && !this.systemDisconnect) {
                this.ConnectionError.emit(WebsocketConnectionErrors.Timeout);
                try {
                  this.socket.close();
                  this.socket = null;
                } catch (ex) {
                  console.log(ex);
                }
              }
            }
          }, 15000);
        }
      } catch (ex) {
        this.ConnectionError.emit(WebsocketConnectionErrors.Unreachable);
      }

    }
  }

  private websocketConnectionOpened() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
    this.reconnects = 0;
    this.IsConnected = true;
    this.WebsocketConnected.emit();
    this.RunPolling();
  }

  private websocketConnectionClosed() {
    if (this.DisconnectTimeout) {
      this.DisconnectTimeout = false;
      this.systemDisconnect = false;
      return;
    }

    if (this.systemDisconnect) {
      this.systemDisconnect = false;
      if (this.IsConnected) {
        this.IsConnected = false;
        clearInterval(this.heartbeatHandler);
      }
      this.Disconnected.emit();
    } else if (this.autoReconnectActive && this.reconnects < 3) {
      if (this.IsConnected) {
        this.IsConnected = false;
        clearInterval(this.heartbeatHandler);
      }
      this.reconnects += 1;
      this.prepareForConnection();
    } else if (!this.waitForConnect) {
      if (this.IsConnected) {
        this.IsConnected = false;
        clearInterval(this.heartbeatHandler);
      }
      this.WebsocketClosed.emit();
    } else {
      this.waitForConnect = false;
      if (this.IsConnected) {
        this.IsConnected = false;
        clearInterval(this.heartbeatHandler);
      }
      this.prepareForConnection();
    }
  }

  public WifiChanged() {
    this.autoReconnectActive = false;
  }

  private websocketConnectionError(error: Event) {
    this.waitForConnect = false;
    this.systemDisconnect = false;

    if (this.IsConnected) {
      this.WebsocketError.emit(error);
    } else {
      this.WebsocketError.emit(WebsocketConnectionErrors.Unreachable);
    }
  }

  private webSocketOnMessage(message: any) {
    if (this.IsConnected) {
      console.log(message);
      if (message.data) {
        this.WebsocketMessage.emit(message.data);
      }

    }
  }

  public SendMessage(message: string) {
    this.socket.send(message);
  }

  private RunPolling() {
    if (this.IsConnected === true) {
      this.heartbeatHandler = setInterval(() => {
        if (this.socket.readyState === this.socket.CLOSING || this.socket.readyState === this.socket.CLOSED) {
          clearInterval(this.heartbeatHandler);
        } else if (this.socket.readyState === this.socket.OPEN) {
          const msg = {
            type: MessageTypes.MESSAGE,
            context: ContextTypes.CLUSTER,
            entity: Entities.HEARTBEAT,
            msgId: uuidV4()
          };
          // console.log('send heartbeat');
          this.socket.send(JSON.stringify(msg));
        }
      }, 1000);
    }
  }

}
