<section fxLayout="column" fxFlexAlign="stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch">
    <h2 fxFlexAlign="center" mat-dialog-title>Download</h2>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <button mat-button (click)="CloseDialog()">
        <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
          <g>
            <rect height="32" width="32" fill="none" />
            <path id="path1" transform="rotate(0,16,16) translate(0.00249958053228738,0) scale(1.00000005960465,1.00000005960465)  " d="M29.590818,0C30.206963,0 30.823227,0.23449993 31.292229,0.70349979 32.229256,1.6405001 32.229256,3.1605005 31.292229,4.1004992L19.398003,15.998498 31.292229,27.892495C32.229256,28.829499 32.229256,30.349496 31.292229,31.294496 30.354227,32.2315 28.827164,32.2315 27.890139,31.294496L15.997988,19.399499 4.1047992,31.296502C3.1677734,32.234498 1.6397347,32.234498 0.70276969,31.296502 -0.23425656,30.352502 -0.23425656,28.832498 0.70276969,27.894502L12.596934,15.998498 0.70276969,4.1024981C-0.23425656,3.1654978 -0.23425656,1.6454973 0.70276969,0.7054987 1.6397347,-0.23150158 3.1677734,-0.23150158 4.1047992,0.7054987L15.99695,12.597497 27.890139,0.70349979C28.35865,0.23449993,28.974674,0,29.590818,0z" />
          </g>
        </svg>
      </button>
    </div>
  </div>

  <p class="variableName">{{variableName}}</p>
  <div fxLayout="row" class="SelectorDiv" *ngIf="!downloadRequested">
    <mat-select class="selectElement" (valueChange)="ValueChangedInSelector($event)">
      <mat-select-trigger>
        {{getValue()}}
      </mat-select-trigger>
      <mat-option *ngFor="let o of options" value="{{getOptionValue(o)}}">
        {{getOptionName(o)}}
      </mat-option>
    </mat-select>
  </div>


    <mat-progress-spinner class="spinner" *ngIf="downloadRequested" mode="indeterminate"></mat-progress-spinner>


  <p class="variableName">{{message}}</p>

  <button class="retryButton" mat-button (click)="StartDownload()" *ngIf="!downloadRequested">
    Download
  </button>
  <!--
  <button class="retryButton" mat-button (click)="AddDemoData()" *ngIf="!downloadRequested">
    TEST
  </button>
  -->
</section>
