import {ModeSwitch} from './enums/mode-switch.enum';

export class ModuleInventoryInfo {
  public ClusterIP: string;
  public ClusterId: string;
  public RecipeId: string;
  public SwitchMode: ModeSwitch;
  public MasterSerial: string;
  public MasterIp: string;
  public RecipeName: string;

  constructor(clusterip: string, clusterid: string, recipeid: string, mode: ModeSwitch, masterSerial: string, masterIp: string) {
    this.ClusterId = clusterid;
    this.ClusterIP = clusterip;
    this.RecipeId = recipeid;
    this.SwitchMode = mode;
    this.MasterIp = masterIp;
    this.MasterSerial = masterSerial;

  }

}
