import {ModuleConnections} from './enums/module-connections.enum';
import {ConveyorTypes} from './conveyor-types';

export class ConveyorBeltInfo {
  public ConnectionPoint: ModuleConnections;
  public OutputName: string;
  public AllowedConveyorBeltTypes: ConveyorTypes[];
  public RestrictedConnections: ModuleConnections[];
  public RelatedInternalBelt: string;
  public PositionRelatedInternalBelt: any;

  constructor() {
    this.AllowedConveyorBeltTypes = [];
    this.RestrictedConnections = [];
  }

  public Copy(): ConveyorBeltInfo {
    const blt = new ConveyorBeltInfo();
    blt.AllowedConveyorBeltTypes = this.AllowedConveyorBeltTypes;
    blt.ConnectionPoint = this.ConnectionPoint;
    blt.RestrictedConnections = this.RestrictedConnections;
    blt.PositionRelatedInternalBelt = this.PositionRelatedInternalBelt;
    blt.RelatedInternalBelt = this.RelatedInternalBelt;
    blt.OutputName = this.OutputName;
    return blt;
  }
}
