<div class="dlg">
  <h2 *ngIf="!connectivity.inOfflineMode" mat-dialog-title>{{'DIALOGS.LOGIN.LOGIN' | i18next }}</h2>
  <h2 *ngIf="connectivity.inOfflineMode" mat-dialog-title>{{'DIALOGS.LOGIN.OFFLINELOGIN' | i18next }}</h2>
  <mat-dialog-content [formGroup]="form" fxLayout="row">
    <mat-form-field>
      <input matInput placeholder="{{'COMMON.MAIL' | i18next }}" formControlName="user">
    </mat-form-field>
    <mat-form-field *ngIf="!connectivity.inOfflineMode">
      <input type="password" matInput placeholder="{{'COMMON.PASSWORD' | i18next }}" formControlName="password" (keydown.enter)="login()">
    </mat-form-field>
    <mat-form-field *ngIf="connectivity.inOfflineMode">
      <input type="password" name="maxisd" autocomplete="off" matInput placeholder="{{'COMMON.PIN' | i18next }}" formControlName="pin" [type]="hide ? 'password' : 'text'" (keydown.enter)="login()">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
  </mat-dialog-content>
  <p class="errortxt">{{ErrorText}}</p>
  <mat-dialog-actions *ngIf="!loginOngoing">
    <button class="mat-raised-button" (click)="login()">{{'DIALOGS.LOGIN.LOGIN' | i18next }}</button>
  </mat-dialog-actions>
  <div class="spinner" fxLayout="row">
    <mat-progress-spinner class="spinner" *ngIf="loginOngoing" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
