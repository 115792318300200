
import {EventEmitter} from '@angular/core';
import {Guid} from 'guid-typescript';
import {EndPointTypes} from './enums/end-point-types.enum';
import {ModuleConnections} from './enums/module-connections.enum';
import {ConveyorLenghtType} from './enums/conveyor-lenght-type.enum';

export class Conveyor {
  public Name: string;
  public SerNumber: string;
  public Lenght: number;
  public Type: string;
  public ProductImage: string;
  public EndPoint: any;
  public EndPointType: EndPointTypes = EndPointTypes.undefined;
  public ConnectionPoint: ModuleConnections;
  public LenghtType: ConveyorLenghtType;
  public Conntected: boolean;

  public TypeOfAddOn: number;
  UniqueId: string;

  public OnlineStateChanged = new EventEmitter();

  constructor(name: string, serialnumber: string, length: number, productImage: string, type: string, lengthType: ConveyorLenghtType, typeOfAddOn: number) {
    this.Name = name;
    this.SerNumber = serialnumber;
    this.Lenght = length;
    this.ProductImage = productImage;
    this.Type = type;
    this.LenghtType = lengthType;
    this.TypeOfAddOn = typeOfAddOn;
    this.SetId(null);
  }

  public setEndPoint(connect: any): void {
    if (connect !== null) {
      this.EndPoint = connect;
      this.EndPointType = EndPointTypes.module;
    } else {
      this.EndPoint = null;
      this.EndPointType = EndPointTypes.sink;
    }
  }

  public Copy(): Conveyor {
    const cv = new Conveyor(this.Name, this.SerNumber, this.Lenght, this.ProductImage, this.Type, this.LenghtType, this.TypeOfAddOn);

    if (this.EndPointType !== EndPointTypes.undefined) {
      cv.setEndPoint(this.EndPoint);
    }
    cv.Conntected = this.Conntected;
    cv.ConnectionPoint = this.ConnectionPoint;

    return cv;
  }

  public SetId(any: any) {
    this.UniqueId = Guid.create().toString();
  }

  public setConveyorOnlineState(connect: boolean) {
    if (connect !== this.Conntected) {
      this.Conntected = connect;
      this.OnlineStateChanged.emit();
    }
  }

  public getConveryorWidth(scaleFactor: number) {
    return (this.Lenght * 10) / scaleFactor;
  }

}
