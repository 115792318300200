import {Feature} from './feature';

export class ComponentTypes {
  public Name: string;
  public Key: string;
  public Features: Feature[];
  public Virtual = false;

  constructor() {
    this.Features = [];
  }


}
