import {ServiceValues} from './service-values';

export class ServiceModule {
  public ModuleSerialNumber: string;
  public ServiceRevision: string;
  public DeviceName: string;
  public Config: ServiceValues[];

  constructor(moduleSerialNumber: string, serviceRevision: string, deviceName: string, config: ServiceValues[]) {
    this.ModuleSerialNumber = moduleSerialNumber;
    this.ServiceRevision = serviceRevision;
    this.DeviceName = deviceName;

    if (config === null) {
      this.Config = [];
    } else {
      this.Config = config;
    }
  }

  addConfigParam(name: string, value: any) {
    if (this.Config.some(ex => ex.ConfigParam === name)) {
      this.Config = this.Config.filter(ex => ex.ConfigParam !== name);
    }
    this.Config.push(new ServiceValues(name, value));
  }

}
