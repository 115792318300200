import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WebsocketService} from './websocket.service';
import {WifiStates} from '../../classes/enums/wifi-states';
import {environment} from '../../../environments/environment';
import {MessageBoxComponent} from '../../components/dialogs/message-box/message-box.component';
import {BluectrlTranslateService} from '../bluectrl-translate.service';
import {MessageHandlingService} from './message-handling.service';
import {MatDialog} from '@angular/material/dialog';
import {OnboardingLogItem} from '../../classes/onboarding-log-item';

@Injectable({
  providedIn: 'root'
})
export class WifiManagementService {

  public ServiceUrl = 'http://localhost:9180';
  private CurrentSSID: string;
  private LastSSID: string;
  private AvailableSSIDs: string[];
  private RefreshHandler: any;
  public ServiceAvailable = true;
  private Intervall = 10000;
  private waitForWifiChangeConfirm = false;
  private wifiChangeOngoing = false;
  private wifiChangeRequested = false;
  private requestedChanges: {
    ssid: string,
    key: string
  };

  public WifiChanged = new EventEmitter<string>();
  public WifiChangeError = new EventEmitter<WifiStates>();
  public AvailableWifisUpdated = new EventEmitter<string[]>();
  public NewLogMessage = new EventEmitter<OnboardingLogItem>();

  constructor(private http: HttpClient,
              private websocket: WebsocketService,
              public translate: BluectrlTranslateService,
              public dialog: MatDialog) {
    this.websocket.WebsocketClosed.subscribe(this.websocketConnectionClosed.bind(this));
    this.websocket.Disconnected.subscribe(this.websocketConnectionClosed.bind(this));
    if (environment.demo === false) {
      this.runRefreshHandler();
    }
  }

  public getCurrentSSID(): string {
    return this.CurrentSSID;
  }

  public getAvailableSSIDs(): string[] {
    return this.AvailableSSIDs;
  }

  public changeWifi(ssid: string, key: string) {
    if (environment.demo === true) {
      // ERROR NOT IMPLEMENTED
      const dialogRef = this.dialog.open(MessageBoxComponent,
        {
          width: 200 + 'px',
          panelClass: 'panelclass',
          data: {text: this.translate.GetTranslation('MESSAGEBOX.CONTENT.SERVICENOTAVAILABLE'), timeout: 1000}
        });
      dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});

    } else {
      if (!this.ServiceAvailable) {
        this.WifiChangeError.emit(WifiStates.noService);
        return;
      }

      this.requestedChanges = {
        key: key,
        ssid: ssid
      };

      if (this.websocket.IsWebsocketConnected()) {
        this.wifiChangeRequested = true;
        this.websocket.Disconnect();
      } else {
        this.runWifiChange();
      }
    }
  }

  private runRefreshHandler() {
    if (!this.RefreshHandler) {
      this.RefreshHandler = setInterval(() => {
        // GET CURRENT SSID
        if (!this.wifiChangeOngoing) {
          this.requestCurrentSSID();

          // GET AVAILABLE SSIDS
          try {
            this.http.get(this.ServiceUrl + '/requestAvailableSSIDs/1', {
              responseType: 'json',
              observe: 'response',
            }).subscribe((ssids: any) => {
              if (ssids && ssids.status === 200) {
                this.ServiceAvailable = true;
                if (ssids.body.Data.SSIDs) {
                  this.AvailableSSIDs = [];

                  for (const s of ssids.body.Data.SSIDs) {
                    this.AvailableSSIDs.push(s as string);
                  }
                } else {
                  this.AvailableSSIDs = [];
                }
              } else {
                this.AvailableSSIDs = [];
              }
              this.AvailableWifisUpdated.emit(this.AvailableSSIDs);
            }, error => {
              this.ServiceAvailable = false;
              this.AvailableSSIDs = [];
              this.AvailableWifisUpdated.emit(this.AvailableSSIDs);
            });
          } catch (e) {
            this.AvailableSSIDs = [];
            this.AvailableWifisUpdated.emit(this.AvailableSSIDs);
            console.log(e);
          }
        }
      }, this.Intervall);
    }
  }

  private websocketConnectionClosed() {
    if (this.wifiChangeRequested) {
      this.wifiChangeRequested = false;
      this.runWifiChange();
    }
  }

  private runWifiChange() {
    if (this.requestedChanges) {
      this.wifiChangeOngoing = true;
      try {
        this.http.get(this.ServiceUrl + '/ChangeWifi/1/' + this.requestedChanges.ssid + '/' + this.requestedChanges.key, {
          responseType: 'json',
          observe: 'response',
        }).subscribe((changeresult: any) => {
          this.websocket.WifiChanged();
          this.wifiChangeOngoing = false;
          if (changeresult.status === 200 && changeresult.body.Data) {
            if (changeresult.body.Data.ErrorCode === 1 || changeresult.body.Data.ErrorCode === 2) {
              this.WifiChangeError.emit(WifiStates.changeError);
            } else {

              this.waitForWifiChangeConfirm = true;

            }
          } else {
            this.WifiChangeError.emit(WifiStates.changeError);
          }
        }, error => {
          this.websocket.WifiChanged();
          this.WifiChangeError.emit(WifiStates.changeError);
        });
      } catch (e) {
        this.WifiChangeError.emit(WifiStates.changeError);
      }
    }
  }

  private requestCurrentSSID() {
    try {
      this.http.get(this.ServiceUrl + '/requestCurrentSSID/1', {
        responseType: 'json',
        observe: 'response',
      }).subscribe((ssid: any) => {
        if (ssid && ssid.status === 200) {
          this.ServiceAvailable = true;
          if (ssid.body.Data.SSID) {

            if (!this.LastSSID) {
              this.LastSSID = this.CurrentSSID;
            }

            if (this.CurrentSSID !== ssid.body.Data.SSID) {
              // WIFI CHANGED???
              if (!this.waitForWifiChangeConfirm) {
                this.websocket.WifiChanged();
                this.WifiChanged.emit(ssid.body.Data.SSID);
              }
            }

            this.CurrentSSID = ssid.body.Data.SSID;

          } else {
            this.CurrentSSID = null;
          }



          if (this.waitForWifiChangeConfirm) {
            this.waitForWifiChangeConfirm = false;
            if (this.requestedChanges.ssid === this.CurrentSSID) {
              this.WifiChanged.emit(this.CurrentSSID);
              this.websocket.WifiChanged();
            } else {
              this.WifiChangeError.emit(WifiStates.changeError);
            }
          }

        } else {
          this.ServiceAvailable = false;
        }
      });
    } catch (e) {
      this.ServiceAvailable = false;
      console.log(e);
    }
  }
}
