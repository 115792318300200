import {Inject, Injectable} from '@angular/core';
import {resolve} from '@angular/compiler-cli';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Injectable({
  providedIn: 'root'
})
export class BluectrlTranslateService {

  // GITHUB FOR I18Next: https://github.com/i18next/i18next-fs-backend
  // NPM FOR I18Next: https://www.npmjs.com/package/angular-i18next
  // HOMEPAGE FOR I18Next: https://www.i18next.com/
  // DEMO REPO FOR I18NEXT: https://github.com/Romanchuk/angular-i18next-demo



  // public textDictionary: { [id: string]: string } = {};
  constructor(@Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) {
  }

  // private InitializeText() {
  //   this.textDictionary = {};
  //
  //   if (this.translate.currentLang) {
  //     // MESSAGE BOX
  //     this.translate.get('MESSAGEBOX').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('MESSAGEBOX', jsonres);
  //     });
  //
  //     this.translate.get('LOGMESSAGES').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('LOGMESSAGES', jsonres);
  //     });
  //
  //     this.translate.get('INFO').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('INFO', jsonres);
  //     });
  //
  //     this.translate.get('MODULES.UNITS').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('MODULES.UNITS', jsonres);
  //     });
  //     this.translate.get('MODULES.COMPONENTS').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('MODULES.COMPONENTS', jsonres);
  //     });
  //     this.translate.get('MODULES.STATES').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('MODULES.STATES', jsonres);
  //     });
  //     this.translate.get('MODULES.TASKS').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('MODULES.TASKS', jsonres);
  //     });
  //     this.translate.get('MODULES.NOTIFICATIONS').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('MODULES.NOTIFICATIONS', jsonres);
  //     });
  //     this.translate.get('MODULES.FAULTCODEDRIVE').subscribe(data => {
  //       const jsonres = JSON.parse(JSON.stringify(data));
  //       this.Decode('MODULES.FAULTCODEDRIVE', jsonres);
  //     });
  //   }
  // }

  // private Decode(prefix: string, data: any) {
  //   const jsonres = JSON.parse(JSON.stringify(data));
  //   for (const key of Object.keys(jsonres)) {
  //     if (Object.keys(data[key]).length <= 0 || Object.keys(data[key])[0] === '0') {
  //       if (JSON.stringify(data[key]).indexOf('"0"') >= 0) {
  //         this.Decode(prefix + '.' + key, data[key]);
  //       } else  {
  //         this.textDictionary[prefix + '.' + key] = data[key];
  //       }
  //
  //     } else {
  //       this.Decode(prefix + '.' + key, data[key]);
  //     }
  //   }
  // }

  // public GetTranslation(key: string): string {
  //   if (this.textDictionary[key]) {
  //     return this.textDictionary[key];
  //   } else {
  //     return 'UNDEFINED';
  //   }
  // }

  public GetTranslation(key: string): string {
    return this.i18NextService.t(key);
  }

  // public async getTranslation(key: string, values: any): Promise<any> {
  //   return await this.translate.get(key, values).toPromise();
  // }
  //
  // public getRealTranslation(key: string, values: any): any {
  //   this.getTranslation(key, values).then(result => {
  //     console.log(result);
  //     return result;
  //   });
  // }

}
