export class VariableStates {
  public State: any;
  public TranslationId: string;
  public DefaultText: string;

  constructor(state: any, component: string, module: string, parameter: string) {
    this.State = state;
    this.DefaultText = state.toString();
    this.TranslationId = 'MODULES.STATES.' + module + '.' + component + '.' + parameter.toUpperCase() + '.' + state.toString().toUpperCase();
  }

  public GetName(): string {
    return this.DefaultText;
  }

  public GetTranslationID(): string {
    return this.TranslationId;
  }
}
