export class ServiceValues {
  public ConfigParam: string;
  public Value: any;

  constructor(configParam: string, value: any) {
    this.ConfigParam = configParam;
    this.Value = value;
  }

}
