import {CustomerModule} from './customer-module';

export class CatalogModule {
  CustomerModule: CustomerModule;
  Counter: number;

  constructor(customerModule: CustomerModule) {
    this.CustomerModule = customerModule;
    this.Counter = 1;
  }
}
