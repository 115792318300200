<div class="main" fxLayout="column">
  <mat-dialog-content>
    <h2><strong>{{data.header}}</strong></h2>
    <p class="content">{{data.content}}</p>
    <div *ngIf="data.elements">
      <table class="infotable" fxLayout="column" *ngFor="let inf of data.elements">
        <tr  class="elementRow" fxLayout="row" fxFlexAlign="stretch">
          <td style="width: 100%" class="elementText"><p class="contentp">{{inf}}</p></td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
  <div class="buttondiv" fxLayout="row" fxFlexAlign="stretch">
    <button class="yesButton" fxFlexAlign="flex-start" (click)="Confirm()">{{'COMMON.OK' | i18next }}</button>
  </div>
</div>
