<section fxLayout="column" fxFlexAlign="stretch">
  <div fxLayout="row" class="moduleheader">
    <mat-checkbox class="checkit" [checked]="Component.ServiceSelected" (change)="CheckBoxSelected($event)"></mat-checkbox>
    <p [ngClass]="{'darkVersion': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode === 4, 'whiteVersion': moduleHandling.CurrentViewMode === 1, 'inventoryTheme': moduleHandling.CurrentViewMode === 3}">{{Component.GetTranslationId() | i18next}} {{Component.getComponentNumber()}}: {{Component.PlcKey}}</p>
  </div>

  <div class="contentd" fxLayout="column" fxFlexAlign="left">
    <table *ngFor="let dv of GetMaintenanceValues()" class="elements" fxLayout="column">
      <tr class="elementRow" fxLayout="row" fxFlexAlign="stretch" [ngClass]="{'elementRowConf': ShowConfig(dv)}" *ngIf="ShowConfigurator(dv) && dv.States.length === 0">
        <td class="elementText descriptionText" *ngIf="dv.States.length === 0"><p class="contentp">{{dv.TranslationId | i18next}}</p></td>
        <td class="elementText"><p class="contentp">{{GetCurrentValue(dv)}}</p> <p *ngIf="dv.States.length === 0" class="contentp">{{GetUnitTranslation(dv)}}</p>
        </td>
      </tr>
      <tr class="elementRow" fxLayout="row" fxFlexAlign="stretch" *ngIf="ShowConfigurator(dv)">
        <app-module-device-configurator *ngIf="ShowConfig(dv)" [deviceValue]="dv" (OnValueChanged)="ValueChanged($event)"></app-module-device-configurator>
      </tr>
      <tr class="elementRow" fxLayout="row" fxFlexAlign="stretch" [ngClass]="{'elementRowConf': ShowConfig(dv)}" *ngIf="!ShowConfigurator(dv)">
        <td style="width: 40%" class="elementText"><p class="contentp">{{dv.GetName()}}</p></td>
      </tr>
      <tr class="elementRow" fxLayout="row" fxFlexAlign="stretch" [ngClass]="{'elementRowConf': ShowConfig(dv)}" *ngIf="!ShowConfigurator(dv)">
        <td style="width: 40%" class="elementText"><p class="contentp3">{{'COMPONENTS.COMPONENT_CONFIG.NOTCONFIGURABLE' | i18next }}</p></td>
      </tr>
    </table>
  </div>
</section>
