export class ModuleRule {
  RuleName: string;
  RuleType: string;
  Inputs: string[];
  ActionName: string;
  Mode: string;
  Silent: boolean;
  TriggerComponent: string;
  TriggerParameter: string;
  TriggerValue: any;
  TargetComponent: string;
  TargetParameter: string;
  TargetValue: string;
}
